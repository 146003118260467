<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>
        <v-row no-gutters v-if="itemSelected">
            <v-col>
                <div>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            color="success" small
                            @click="approve"
                            :loading="buttonLoading">
                            通過
                        </v-btn>
                        <v-btn
                            color="error" small
                            @click="openReviewDialog()">
                            拒絕
                        </v-btn>
                    </v-toolbar>
                </div>
            </v-col>
        </v-row>
        <v-card outlined>
            <v-data-table
                :headers="headers"
                :items="dialogs"
                :items-per-page="10"
                :loading="dataLoading">
                <template v-slot:item.thumbnail="{ item }">
                    <v-tooltip right color="grey darken-3">
                        <template v-slot:activator="{ on, attrs }">
                            <v-img :src="item.thumbnail"
                                class="image-thumbnail  mx-auto my-2"
                                v-bind="attrs" v-on="on">
                            </v-img>
                        </template>
                        <div class="text-bubble">
                            <img class="image image-block" :src="item.thumbnail" />
                            <div class="text-area" :style="getTextArea(item.height)">
                                <span class="text-area-text my-auto black--text">
                                    紅色區塊為文字區域，<br>最好留空沒有圖樣</span>
                            </div>  
                        </div>
                    </v-tooltip>
                </template>
                <template #item.status="{ item }">
                    <span :class="{ 'red--text': item.status === -1, 'green--text': item.status === 1 }">
                        {{ getStatusText(item.status) }}
                    </span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn small class="mr-2 mt-1" outlined @click="gotoReviewDialogDetail(item.application_id)">
                        <v-icon small class="mr-1"> mdi-eye </v-icon>
                        查看
                    </v-btn>
                </template>
                <template v-slot:item.checkbox="{ item }">
                    <div class="checkbox-container">
                        <v-checkbox v-if="item.status === 0" v-model="item.checked"></v-checkbox>
                    </div>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="reviewDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">拒絕對話框申請</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="reviewForm">
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>拒絕原因</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-text-field label="" v-model="reviewMessage"
                                        :rules="reviewMessageRules" dense hide-details="auto"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="reviewDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveReview" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { reviewServices } from "@/services/review.js";
export default {
    name: "reviewDialogList",
    components: {
    },
    data() {
        return {
            breadcrumbsItems: [],
            infoMode: 'show',
            headers: [
                {
                    text: '縮圖',
                    sortable: false,
                    value: 'thumbnail',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '主題',
                    value: 'theme',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '申請日期',
                    value: 'apply_date',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '狀態',
                    value: 'status',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '功能', value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '批次審核', value: 'checkbox', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
            ],
            dialogs: [],
            itemSelected: false,
            reviewDialog: false,
            reviewMessage: '',
            reviewMessageRules: [(v) => !!v || '請輸入審核意見'],
            buttonLoading: false,
            dataLoading: false,
            detail: {
                center_x: 170,
                center_y: 190,
                center_width: 860,
                center_height: 520,
            },
            ratio: 400 / 1200,
            previeItem: {},
        };
    },
    async created() {
        this.breadcrumbsItems.push({
            text: '對話框審核',
            disabled: true,
            href: ``,
        });
        this.dataLoading = true;
        this.fetchData();
    },
    mounted() {
    },
    watch: {
        dialogs: {
            handler(dialogs) {
                this.itemSelected = false;
                dialogs.forEach(item => {
                    if (item.checked) {
                        this.itemSelected = true;
                    }
                });
            },
            deep: true
        }
    },
    methods: {
        gotoReviewDialogDetail(id) {
            this.$router.push(`/console/review/dialogs/${id}`);
        },
        getStatusText(statusCode) {
            switch (statusCode) {
                case 0:
                    return '待審核';
                case 1:
                    return '已上架';
                default:
                    return '已拒絕';
            }
        },
        async fetchData() {
            const response = await reviewServices.getReviewImageList('2');
            let i = 0;
            for (let image of response.images) {
                this.dialogs.push({
                    id: i,
                    image_id: image.image_id,
                    application_id: image.application_id,
                    apply_date: image.apply_date,
                    thumbnail: image.image_url,
                    status: image.status,
                    theme: image.theme,
                    checked: false,
                    height: image.height,
                });
                i++;
            }
            this.dataLoading = false;
        },
        async approve() {
            this.buttonLoading = true;
            const data = {
                status: 1
            }
            /// send review to all selected items
            for (let item of this.dialogs) {
                if (item.checked) {
                    await reviewServices.sendReviewImageResult(item.application_id, data)
                }
            }
            this.dialogs = [];
            await this.fetchData();
            this.buttonLoading = false;
        },
        openReviewDialog() {
            this.reviewDialog = true;
        },
        async saveReview() {
            if (this.$refs.reviewForm.validate()) {
                this.buttonLoading = true;
                const data = {
                    status: -1,
                    comment: this.reviewMessage
                }
                /// send review to all selected items
                for (let item of this.dialogs) {
                    if (item.checked) {
                        await reviewServices.sendReviewImageResult(item.application_id, data)
                    }
                }
                this.dialogs = [];
                await this.fetchData();
                this.buttonLoading = false;
                this.reviewDialog = false;
            }
        },
        getTextArea(height) {
            if (height == 900) {
                return {
                    width: '286.667px',
                    height: '173.33px',
                    top: '63.33px',
                    left: '56.667px',
                };
            } else {
                return {
                    width: '246.667px',
                    height: '246.667px',
                    top: '76.667px',
                    left: '76.667px',
                };
            }
        }
    },
};
</script>

<style scoped>
.image-thumbnail {
    width: 70px;
}

.checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-block {
    width: 400px;
}

.image {
    display: block;
}

.text-bubble {
    position: relative;
}

.text-bubble>.text-area {
    position: absolute;
    background-color: rgba(255, 0, 0, 0.5);
}

.text-bubble>.text-area>.text-area-text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}
</style>