<template>
    <div id="loginRedirect">
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64" color="deep-orange lighten-2"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import { loginServices } from '@/services/login.js';
export default {
    name: "loginRedirect",
    components: {},
    data() {
        return {
            code: '',
            overlay: true,
        };
    },
    async created() {
        this.code = this.$route.query.code;
        //Get userInfo from backend
        await this.getUserInfo();
    },
    mounted() {

    },
    methods: {
        async getUserInfo() {
            //Get userInfo from backend
            const data = {
                code: this.code,
            }
            const res = await loginServices.getUserInfo(data);
            if (res.user_name) {
                this.$router.push('/console/drawer');
            }
        }
    },
};
</script>

<style scoped></style>