<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>
        <v-row no-gutters>
            <v-col>
                <v-tabs v-model="tab">
                    <v-tabs-slider color="deep-orange arken-1"></v-tabs-slider>

                    <v-tab href="#tabInfo" class="grey--text text--darken-2 subtitle-1">
                        基本資料
                    </v-tab>
                    <v-tab href="#tabEmotion" class="grey--text text--darken-2 subtitle-1">
                        情緒列表
                    </v-tab>
                    <v-tab v-if="canEdit" href="#tabEmotionBatch" class="grey--text text--darken-2 subtitle-1">
                        情緒批次上傳
                    </v-tab>
                </v-tabs>
            </v-col>
            <v-col>
                <v-row>
                    <v-spacer></v-spacer>
                    <div v-if="applyButtonShow">
                        <v-toolbar flat>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-3" outlined small
                                @click="apply()"
                                :loading="buttonLoading"
                                :disabled="applyButtonDisabled">
                                {{ applyButtonDisabled ? '申請審核中' : '送出審核申請' }}
                            </v-btn>
                        </v-toolbar>
                    </div>
                    <div v-if="revokeApplyButtonShow">
                        <v-toolbar flat>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-3" outlined small
                                @click="revokeApply()"
                                :loading="buttonLoading">
                                取消審核申請
                            </v-btn>
                        </v-toolbar>
                    </div>
                </v-row>
                <div v-if="revokeButtonShow">
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-3" outlined small
                            @click="revoke()"
                            :loading="buttonLoading">
                            角色下架
                        </v-btn>
                    </v-toolbar>
                </div>
            </v-col>
        </v-row>

        <v-tabs-items v-model="tab" class="mt-2">
            <v-tab-item value="tabInfo">
                <div v-if="roleApplicationStatus.code == 3">
                    <v-alert
                        outlined
                        color="yellow darken-3">
                        <div class="text-h6 commonColor">
                            審核意見
                        </div>
                        <div class="text-body-1 commonColor">{{ roleApplicationStatus.message }}</div>
                    </v-alert>
                </div>
                <v-card outlined>
                    <v-card flat class="border-bottom" v-for="input in titles" :key="input.id">
                        <v-speed-dial class="edit-option-fab" v-model="input.editOptionFab" top right direction="left"
                            transition="slide-y-reverse-transition" open-on-hover
                            v-if="canEdit">
                            <template v-slot:activator>
                                <v-btn v-model="input.editOptionFab" small fab text depressed>
                                    <v-icon v-if="input.editOptionFab">
                                        mdi-close
                                    </v-icon>
                                    <v-icon v-else>
                                        mdi-cog
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-btn fab dark x-small color="green" @click="openEditRoleTitleDialog(input)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn fab dark x-small color="red" v-if="titles.length > 1">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-speed-dial>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>語言</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                {{ input.localeName }}

                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>角色名稱</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                {{ input.title }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>角色敘述</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                {{ input.description }}
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat class="border-bottom">
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>新增角色名稱</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                <v-select v-model="localeSelector" :items="locales" item-text="name" item-value="id"
                                    label="語言" outlined
                                    dense single-line hide-details="auto"></v-select>
                                <v-btn class="mt-1" rounded small color="primary" dark @click="addTitle()">
                                    <v-icon small dark>mdi-plus</v-icon>新增
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat class="border-bottom">
                        <v-speed-dial class="edit-option-fab" v-model="roleBirthdayEditOptionFab" top right
                            direction="left"
                            transition="slide-y-reverse-transition" open-on-hover v-if="canEdit">
                            <template v-slot:activator>
                                <v-btn v-model="roleBirthdayEditOptionFab" small fab text depressed>
                                    <v-icon v-if="roleBirthdayEditOptionFab">
                                        mdi-close
                                    </v-icon>
                                    <v-icon v-else>
                                        mdi-cog
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-btn fab dark x-small color="green" @click="openEditBirthdayDialog()">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </v-speed-dial>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>生日</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                {{ roleBirthday }}
                                <span v-if="!roleBirthday">尚未設定</span>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat class="border-bottom">
                        <v-speed-dial class="edit-option-fab" v-model="roleGenderEditOptionFab" top right
                            direction="left"
                            transition="slide-y-reverse-transition" open-on-hover v-if="canEdit">
                            <template v-slot:activator>
                                <v-btn v-model="roleGenderEditOptionFab" small fab text depressed>
                                    <v-icon v-if="roleGenderEditOptionFab">
                                        mdi-close
                                    </v-icon>
                                    <v-icon v-else>
                                        mdi-cog
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-btn fab dark x-small color="green" @click="openEditGenderDialog()">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </v-speed-dial>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>性別</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                <span v-if="!roleGender">尚未設定</span>
                                <span v-else>{{ roleGender == 'm' ? '男性' : '女性' }}</span>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat class="border-bottom">
                        <v-speed-dial class="edit-option-fab" v-model="roleThumbnail.editOptionFab" top right
                            direction="left"
                            transition="slide-y-reverse-transition" open-on-hover v-if="canEdit">
                            <template v-slot:activator>
                                <v-btn v-model="roleThumbnail.editOptionFab" small fab text depressed>
                                    <v-icon v-if="roleThumbnail.editOptionFab">
                                        mdi-close
                                    </v-icon>
                                    <v-icon v-else>
                                        mdi-cog
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-btn fab dark x-small color="green" @click="openEditRoleThumbnailDialog">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </v-speed-dial>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>Logo</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                <v-img class="thumbnail-block" :src="roleThumbnail.imgUrl"
                                    v-if="roleThumbnail.imgUrl"></v-img>
                                <span v-if="!roleThumbnail.imgUrl">尚未設定</span>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat class="border-bottom">
                        <v-speed-dial class="edit-option-fab" v-model="roleFace.editOptionFab" top right
                            direction="left"
                            transition="slide-y-reverse-transition" open-on-hover v-if="canEdit">
                            <template v-slot:activator>
                                <v-btn v-model="roleFace.editOptionFab" small fab text depressed>
                                    <v-icon v-if="roleFace.editOptionFab">
                                        mdi-close
                                    </v-icon>
                                    <v-icon v-else>
                                        mdi-cog
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-btn fab dark x-small color="green" @click="openEditRoleFaceDialog">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </v-speed-dial>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>Face</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                <v-img class="thumbnail-block" :src="roleFace.imgUrl" v-if="roleFace.imgUrl"></v-img>
                                <span v-if="!roleFace.imgUrl">尚未設定</span>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tabEmotion">
                <v-card outlined class="py-3 px-5">
                    <v-data-table :headers="headers" :items="emotions" :items-per-page="-1" disable-sort>
                        <template v-slot:item.thumbnailUrl="{ item }">
                            <v-img v-if="item.thumbnailUrl != null" :src="item.thumbnailUrl" alt="尚未設定"
                                class="role-thumbnail mx-auto my-2">
                            </v-img>
                            <span v-if="!item.thumbnailUrl">尚未設定</span>
                        </template>

                        <template v-slot:item.imgUrl="{ item }">
                            <v-img v-if="item.imgUrl != null" :src="item.imgUrl"
                                alt="尚未設定" class="role-thumbnail mx-auto my-2">
                            </v-img>
                            <span v-if="!item.imgUrl">尚未設定</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn small class="mr-2 mt-1" outlined @click="editEmotion(item)"
                                v-if="canEdit">
                                <v-icon small class="mr-1"> mdi-eye </v-icon>
                                編輯
                            </v-btn>
                            <v-btn small class="mr-2 mt-1" outlined @click="previewEmotion(item)"
                                v-if="item.imgUrl">
                                <v-icon small class="mr-1"> mdi-eye </v-icon>
                                預覽
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tabEmotionBatch">
                <v-card outlined class="py-3 px-5">
                    <v-data-table class="my-4" :headers="batchFileHeaders" :items="files"
                        :items-per-page=batchTableItemsPerPage
                        hide-default-footer :sort-by="batchTableSort">
                        <template v-slot:item.thumb="{ item }">
                            <v-img v-if="item.thumb != null" :src="item.thumb" alt="無圖片"
                                class="batch-thumbnail mx-auto my-2">
                            </v-img>
                            <span v-if="!item.thumb">無圖片</span>
                        </template>
                        <template v-slot:item.size="{ item }">
                            {{ formatSize(item.size) }}
                        </template>
                        <template v-slot:item.status="{ item }">
                            <span v-if="item.error">{{ item.error }}</span>
                            <span v-else-if="item.emotionError">{{ item.emotionError }}</span>
                        </template>
                        <template v-slot:item.uploadable="{ item }">
                            <v-icon color="success" v-if="item.uploadable">mdi-check</v-icon>
                            <v-icon color="error" v-else>mdi-close</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn small class="mr-2 mt-1" outlined @click="$refs.upload.remove(item)">
                                <v-icon small class="mr-1"> mdi-delete </v-icon>
                                刪除
                            </v-btn>
                        </template>
                    </v-data-table>
                    <v-divider class="my-2"></v-divider>
                    <v-card-text v-if="batchFilesUploading">
                        <v-progress-linear :buffer-value=batchFilesUploadingBufferValue :value=batchFilesUploadingValue
                            stream
                            color="orange"></v-progress-linear>
                        <v-row class="my-2">
                            <v-spacer></v-spacer>
                            <span>{{ `上傳進度: ${batchCompleted} / ${batchTotal}` }}</span>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn outlined small class="mr-2">
                            <file-upload ref="upload" v-model="files" :extensions="extensions" @input-file="inputFile"
                                @input-filter="inputFilter" :accept="accept" :multiple="true" :size="fileSizeLimit">
                                <v-icon small left>mdi-plus</v-icon>
                                選擇檔案
                            </file-upload>
                        </v-btn>
                        <v-btn outlined small @click="onAddFolder">
                            <v-icon small left>mdi-plus</v-icon>
                            選擇資料夾</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn outlined small color="success" @click="uploadFiles" :loading="batchFilesUploading"
                            :disabled="batchFilesUploading">
                            <v-icon small>mdi-upload</v-icon>
                            上傳檔案</v-btn>
                    </v-card-actions>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog v-model="editRoleTitleDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">編輯資料 - {{ editTitle.localeName }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="roleTitleForm" v-model="isEditTitleValid" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>角色名稱</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-text-field label="名稱" v-model="editTitle.title" :rules="roleTitleRules"
                                        single-line dense
                                        hide-details="auto"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>角色敘述</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-text-field label="敘述" v-model="editTitle.description" :rules="roleTitleRules"
                                        single-line
                                        dense hide-details="auto"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editRoleTitleDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveTitle" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editBirthdayDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">編輯角色生日</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="birthdayForm">
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>生日</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-text-field label="" v-model="editRoleBirthday"
                                        hint="格式為 月份/日期 ，不需補0，例如3/15、10/13、12/5"
                                        persistent-hint single-line dense hide-details="auto"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editBirthdayDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveBirthday" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editRoleGenderDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">編輯角色性別</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="genderForm">
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>性別</v-subheader>
                                </v-col>
                                <v-select v-model="selectedRoleGender" :items="genders" item-text="name" item-value="id"
                                    label="性別" outlined dense single-line hide-details="auto"></v-select>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editRoleGenderDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveGender" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editRoleThumbnailDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">編輯角色大頭照</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="thumbnailForm" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>角色大頭照</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-img class="thumbnail-block" :src="roleThumbnail.imgUrl"
                                        v-if="roleThumbnail.imgUrl && !editedRoleThumbnail.editThumbnailFiles"></v-img>
                                    <div class="thumbnail-block" v-if="editedRoleThumbnail.editThumbnailFiles">
                                        <div ref="roleThumbnailBlock">
                                            <vueCropper ref="roleThumbnailCropper" :img="roleThumbnailOption.img"
                                                :mode="option.mode" :canScale="option.canScale"
                                                :output-size="option.size"
                                                :output-type="option.outputType" :info="false" :full="option.full"
                                                :fixed="option.fixed" :fixed-number="roleThumbnailOption.fixedNumber"
                                                :can-move="option.canMove" :can-move-box="option.canMoveBox"
                                                :fixed-box="option.fixedBox" :original="option.original"
                                                :auto-crop="option.autoCrop"
                                                :auto-crop-width="roleThumbnailOption.autoCropWidth"
                                                :auto-crop-height="roleThumbnailOption.autoCropHeight"
                                                :center-box="option.centerBox">
                                            </vueCropper>
                                        </div>
                                    </div>
                                    <v-file-input v-model="editedRoleThumbnail.editThumbnailFiles" label="Logo"
                                        accept="image/png, image/jpeg" prepend-icon="mdi-camera"
                                        @change="$uploadImage(editedRoleThumbnail.editThumbnailFiles, roleThumbnailOption)"></v-file-input>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editRoleThumbnailDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveRoleThumbnail" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editRoleFaceDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">編輯角色小圖示</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="thumbnailForm" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>角色小圖示</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-img class="thumbnail-block" :src="roleFace.imgUrl"
                                        v-if="roleFace.imgUrl && !editRoleFace.editRoleFaceFile"></v-img>
                                    <div class="thumbnail-block" v-if="editRoleFace.editRoleFaceFile">
                                        <div ref="roleFaceBlock">
                                            <vueCropper ref="roleFaceCropper" :img="editRoleFaceOption.img"
                                                :mode="option.mode"
                                                :canScale="option.canScale" :output-size="option.size"
                                                :output-type="option.outputType" :info="false" :full="option.full"
                                                :fixed="option.fixed" :fixed-number="editRoleFaceOption.fixedNumber"
                                                :can-move="option.canMove" :can-move-box="option.canMoveBox"
                                                :fixed-box="option.fixedBox" :original="option.original"
                                                :auto-crop="option.autoCrop"
                                                :auto-crop-width="editRoleFaceOption.autoCropWidth"
                                                :auto-crop-height="editRoleFaceOption.autoCropHeight"
                                                :center-box="option.centerBox">
                                            </vueCropper>
                                        </div>
                                    </div>
                                    <v-file-input v-model="editRoleFace.editRoleFaceFile" label="face"
                                        accept="image/png, image/jpeg" prepend-icon="mdi-camera"
                                        @change="$uploadImage(editRoleFace.editRoleFaceFile, editRoleFaceOption)"></v-file-input>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editRoleFaceDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveRoleFace" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="emotionEditDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">編輯情緒圖片 - {{ editedItem.name }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="emotionForm" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>縮圖</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-img class="thumbnail-block" :src="editedItem.thumbnailUrl"
                                        v-if="editedItem.thumbnailUrl && !editedItem.editThumbnailFile"></v-img>
                                    <div class="d-flex" v-if="editedItem.editThumbnailFile">
                                        <div class="thumbnail-block">
                                            <div ref="editEmotionThumbnailBlock" class="edit-img-container">
                                                <vueCropper ref="editEmotionThumbnailCropper"
                                                    :img="editEmotionThumbnailOption.img"
                                                    :mode="editEmotionThumbnailOption.mode"
                                                    :canScale="editEmotionThumbnailOption.canScale"
                                                    :output-size="option.size"
                                                    :output-type="option.outputType" :info="false" :full="option.full"
                                                    :fixed="option.fixed"
                                                    :fixed-number="editEmotionThumbnailOption.fixedNumber"
                                                    :can-move="option.canMove" :can-move-box="option.canMoveBox"
                                                    :fixed-box="option.fixedBox"
                                                    :original="editEmotionThumbnailOption.original"
                                                    :auto-crop="option.autoCrop"
                                                    :auto-crop-width="editEmotionThumbnailOption.autoCropWidth"
                                                    :auto-crop-height="editEmotionThumbnailOption.autoCropHeight"
                                                    :center-box="editEmotionThumbnailOption.centerBox">
                                                </vueCropper>
                                            </div>
                                        </div>
                                        <div>
                                            <v-btn class="ma-2" outlined @click="refreshThumbnailCropper">reset</v-btn>
                                            <v-row class="mt-2">
                                                <v-col cols="12" class="text-center">
                                                    <v-btn icon @click="thumbnailScale -= 0.5">
                                                        <v-icon>mdi-minus</v-icon>
                                                    </v-btn>
                                                    <v-btn icon @click="thumbnailScale += 0.5">
                                                        <v-icon>mdi-plus</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                    <v-file-input v-model="editedItem.editThumbnailFile" label="情緒縮圖"
                                        accept="image/png, image/jpeg" :rules="emotionThumbnailFileRules"
                                        prepend-icon="mdi-camera"
                                        @change="$uploadImage(editedItem.editThumbnailFile, editEmotionThumbnailOption)"></v-file-input>

                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>大圖</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-img class="emo-img-block" :src="editedItem.imgUrl"
                                        v-if="editedItem.imgUrl && !editedItem.editImgFile"></v-img>
                                    <div class="d-flex" v-if="editedItem.editImgFile">
                                        <div class="emo-img-block">
                                            <!-- <div class="emo-img-block"
                                        :style="`width: ${editEmotionImgOption.autoCropWidth}px; height: ${editEmotionImgOption.autoCropHeight}`"> -->
                                            <div ref="editEmotionImgBlock" class="edit-img-container">
                                                <vueCropper ref="editEmotionImgCropper" :img="editEmotionImgOption.img"
                                                    :canScale="editEmotionImgOption.canScale" :output-size="option.size"
                                                    :output-type="option.outputType" :info="false" :full="option.full"
                                                    :fixed="option.fixed" :mode="editEmotionImgOption.mode"
                                                    :fixed-number="editEmotionImgOption.fixedNumber"
                                                    :can-move="option.canMove"
                                                    :can-move-box="option.canMoveBox" :fixed-box="option.fixedBox"
                                                    :original="editEmotionImgOption.original"
                                                    :auto-crop="option.autoCrop"
                                                    :auto-crop-width="editEmotionImgOption.autoCropWidth"
                                                    :auto-crop-height="editEmotionImgOption.autoCropHeight"
                                                    :center-box="editEmotionImgOption.centerBox">
                                                </vueCropper>
                                                <div v-if="showReferenceLines"
                                                    class="reference-line horizontal-line first-line" />
                                                <div v-if="showReferenceLines"
                                                    class="reference-line horizontal-line second-line" />
                                                <div v-if="showReferenceLines"
                                                    class="reference-line horizontal-line face-line" />
                                                <div v-if="showReferenceLines"
                                                    class="reference-line horizontal-line base-line" />
                                                <div v-if="showReferenceLines"
                                                    class="reference-line vertical-line first-ver-line" />
                                                <div v-if="showReferenceLines"
                                                    class="reference-line vertical-line second-ver-line" />
                                            </div>
                                        </div>
                                        <div>
                                            <v-btn @click="showReferenceLines = !showReferenceLines" icon>
                                                <v-icon>{{ showReferenceLines ? 'mdi-eye-off' : 'mdi-eye'
                                                    }}</v-icon>
                                            </v-btn>
                                            <v-btn class="ma-2" outlined @click="refreshCropper">reset</v-btn>
                                            <v-row class="mt-2">
                                                <v-col cols="12" class="text-center">
                                                    <v-btn icon @click="scale -= 0.5">
                                                        <v-icon>mdi-minus</v-icon>
                                                    </v-btn>
                                                    <v-btn icon @click="scale += 0.5">
                                                        <v-icon>mdi-plus</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                    <v-file-input v-model="editedItem.editImgFile" label="情緒大圖"
                                        accept="image/png, image/jpeg"
                                        :rules="emotionImgFileRules" prepend-icon="mdi-camera"
                                        @change="$uploadImage(editedItem.editImgFile, editEmotionImgOption)"></v-file-input>
                                    <!-- <div v-if="editedItem.editImgFile"> -->
                                    <!-- plus and minus buttons to edit image scale -->
                                    <!-- <v-row class="mt-2">
                                            <v-col cols="12" class="text-center">
                                                <v-btn icon @click="scale -= 0.5">
                                                    <v-icon>mdi-minus</v-icon>
                                                </v-btn>
                                                <v-btn icon @click="scale += 0.5">
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div> -->
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="emotionEditDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveEmotion" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="previewEmotionDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">情緒預覽 - {{ previewItem.name }}</span>
                </v-card-title>
                <v-card-text>
                    <v-img class="preview-image mx-auto" :src="previewItem.image" v-if="previewItem.image">
                    </v-img>
                    <v-row v-if="!previewItem.image">
                        <v-spacer></v-spacer>
                        <v-progress-circular indeterminate></v-progress-circular>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-3" outlined small @click="previewEmotionDialog = false">
                        結束預覽
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { VueCropper } from 'vue-cropper';
import { roleServices } from '@/services/role.js';
import FileUpload from 'vue-upload-component';

export default {
    name: "roleInfo",
    components: {
        VueCropper, FileUpload
    },
    data() {
        return {
            roleId: '',
            breadcrumbsItems: [],
            buttonLoading: false,
            tab: null,
            infoMode: 'show',
            localeSelector: '',
            locales: [{
                id: 'zh',
                name: '中文',
                disabled: false
            }, {
                id: 'en',
                name: '英文',
                disabled: false
            }],
            titles: [],
            editRoleTitleDialog: false,
            isEditTitleValid: true,
            editTitle: {
                title: '',
                locale: '',
                localeName: ''
            },
            roleTitleRules: [(v) => !!v || "必須填寫"],
            scale: 0,
            // lastScale: 0,
            thumbnailScale: 0,
            option: this.$cropperOption,
            editRoleThumbnailDialog: false,
            roleThumbnail: {
                editOptionFab: false,
                imgUrl: '',
            },
            editedRoleThumbnail: {
                imgUrl: '',
                editThumbnailFiles: ''
            },
            roleThumbnailOption: {
                img: '',
                fixedNumber: [1, 1],
                autoCropHeight: 150,
                autoCropWidth: 150,
            },
            roleFace: {
                editOptionFab: false,
                imgUrl: '',
            },
            editRoleFaceDialog: false,
            editRoleFace: {
                imgUrl: '',
                editRoleFaceFile: ''
            },
            editRoleFaceOption: {
                img: '',
                fixedNumber: [1, 1],
                autoCropHeight: 150,
                autoCropWidth: 150,
            },
            editEmotionThumbnailOption: {
                img: '',
                fixedNumber: [1, 1],
                // autoCropHeight: 150,
                // autoCropWidth: 150,
                autoCropHeight: 300,
                autoCropWidth: 300,
                centerBox: false,
                mode: 'contain',
                original: false,
                canScale: true,
            },
            editEmotionImgOption: {
                img: '',
                fixedNumber: [5, 6],
                // autoCropHeight: 180,
                // autoCropWidth: 150,
                autoCropHeight: 360,
                autoCropWidth: 300,
                centerBox: false,
                // centerBox: true,
                mode: 'contain',
                original: false,
                canScale: true,
            },
            emotionThumbnailFileRules: [(v) => (!!v || !!this.editedItem.thumbnailUrl) || "必須上傳圖片"],
            emotionImgFileRules: [(v) => (!!v || !!this.editedItem.imgUrl) || "必須上傳圖片"],
            roleBirthdayEditOptionFab: false,
            roleBirthday: '',
            editRoleBirthday: '',
            editBirthdayDialog: false,
            roleGenderEditOptionFab: false,
            roleGender: '',
            selectedRoleGender: '',
            editRoleGenderDialog: false,
            genders: [{
                id: 'm',
                name: '男性',
                disabled: false
            }, {
                id: 'f',
                name: '女性',
                disabled: false
            }],
            headers: [
                {
                    text: '情緒名稱', value: 'name',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '縮圖',
                    sortable: false,
                    value: 'thumbnailUrl',
                    align: 'center',
                    class: 'grey lighten-4',
                }, {
                    text: '情緒大圖',
                    sortable: false,
                    value: 'imgUrl',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '建立日期',
                    value: 'createdAt',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '修改日期',
                    value: 'updatedAt',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '功能', value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
            ],
            emotions: [{
                id: '3',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.13',
                name: '快樂',
                type: 'like',
            },
            {
                id: '4',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.15',
                name: '得意',
                type: 'like',
            },

            {
                id: '5',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.14',
                name: '輕鬆',
                type: 'like',
            },

            {
                id: '6',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.9',
                name: '幸福',
                type: 'like',
            },

            {
                id: '7',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.2',
                name: '興奮',
                type: '',
            },

            {
                id: '8',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.1',
                name: '平靜',
                type: '',
            },

            {
                id: '9',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.16',
                name: '驚訝',
                type: '',
            },

            {
                id: 'A',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.19',
                name: '害怕',
                type: 'hate',
            },

            {
                id: 'B',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.20',
                name: '難過',
                type: 'hate',
            },

            {
                id: 'C',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.10',
                name: '愧疚',
                type: 'hate',
            },

            {
                id: 'D',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.7',
                name: '挫折',
                type: 'hate',
            },

            {
                id: 'E',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.12',
                name: '丟臉',
                type: 'hate',
            },

            {
                id: 'F',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.18',
                name: '緊張',
                type: 'hate',
            },

            {
                id: '11',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.8',
                name: '失望',
                type: 'hate',
            },

            {
                id: '12',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.4',
                name: '焦慮',
                type: 'hate',
            },

            {
                id: '13',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.5',
                name: '擔心',
                type: 'hate',
            },

            {
                id: '14',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.6',
                name: '無助',
                type: 'hate',
            },

            {
                id: '15',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.3',
                name: '嫉妒',
                type: 'hate',
            },

            {
                id: '16',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.11',
                name: '厭煩',
                type: 'hate',
            },

            {
                id: '17',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.17',
                name: '生氣',
                type: 'hate',
            }],
            emotionEditDialog: false,
            editedItem: {
                emotionId: '',
                name: '',
                thumbnailUrl: '',
                imgUrl: '',
                editThumbnailFile: '',
                editImgFile: ''
            },
            roleApplicationStatus: {
                code: 1,
                message: ''
            },
            applyButtonDisabled: false,
            applyButtonShow: false,
            revokeButtonShow: false,
            canEdit: true,
            revokeApplyButtonShow: false,
            previewItem: {
                imgId: '',
                emotionId: '',
                name: ''
            },
            previewEmotionDialog: false,
            /// Upload file in batch
            files: [],
            batchFileHeaders: [
                {
                    text: '圖片檢視',
                    sortable: false,
                    value: 'thumb',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '檔案名稱', value: 'name',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '情緒名稱',
                    value: 'emotion',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '類型',
                    value: 'style',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '大小',
                    value: 'size',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '可上傳',
                    value: 'uploadable',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '說明',
                    value: 'status',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '功能', value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
            ],
            extensions: 'png',
            accept: 'image/png',
            fileSizeLimit: 1024 * 1024 * 2,
            batchEmotions: [{
                id: '3',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.13',
                name: '快樂',
                uploadable: false,
            },
            {
                id: '4',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.15',
                name: '得意',
                uploadable: false,
            },

            {
                id: '5',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.14',
                name: '輕鬆',
                uploadable: false,
            },

            {
                id: '6',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.9',
                name: '幸福',
                uploadable: false,
            },

            {
                id: '7',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.2',
                name: '興奮',
                uploadable: false,
            },

            {
                id: '8',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.1',
                name: '平靜',
                uploadable: false,
            },

            {
                id: '9',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.16',
                name: '驚訝',
                uploadable: false,
            },

            {
                id: 'A',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.19',
                name: '害怕',
                uploadable: false,
            },

            {
                id: 'B',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.20',
                name: '難過',
                uploadable: false,
            },

            {
                id: 'C',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.10',
                name: '愧疚',
                uploadable: false,
            },

            {
                id: 'D',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.7',
                name: '挫折',
                uploadable: false,
            },

            {
                id: 'E',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.12',
                name: '丟臉',
                uploadable: false,
            },

            {
                id: 'F',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.18',
                name: '緊張',
                uploadable: false,
            },

            {
                id: '11',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.8',
                name: '失望',
                uploadable: false,
            },

            {
                id: '12',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.4',
                name: '焦慮',
                uploadable: false,
            },

            {
                id: '13',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.5',
                name: '擔心',
                uploadable: false,
            },

            {
                id: '14',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.6',
                name: '無助',
                uploadable: false,
            },

            {
                id: '15',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.3',
                name: '嫉妒',
                uploadable: false,
            },

            {
                id: '16',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.11',
                name: '厭煩',
                uploadable: false,
            },

            {
                id: '17',
                thumbnailId: '',
                imgId: '',
                msgCode: 'diary.emotion.17',
                name: '生氣',
                uploadable: false,
            }],
            batchTableItemsPerPage: -1,
            // batchTableSort: ['emotion'],
            batchTableSort: ['emotion', 'style'],
            batchEmotionToUpload: [],
            batchFilesUploading: false,
            batchFilesUploadingBufferValue: 40,
            batchFilesUploadingValue: 20,
            batchCompleted: 0,
            batchTotal: 0,
            showReferenceLines: false,
        };
    },
    async created() {
        this.breadcrumbsItems.push({
            text: '角色管理',
            disabled: true,
            href: ``,
        });
        this.roleId = this.$route.params.rid;
        const roleInfo = await roleServices.getInfo(this.roleId);
        if (roleInfo.names) {
            let i = 1;
            roleInfo.names.forEach(item => {
                let localeName = this.disableLocale(item.locale);
                this.titles.push({
                    editOptionFab: false,
                    id: i,
                    title: item.name,
                    locale: item.locale,
                    localeName: localeName,
                    description: item.description
                });
                i += 1;
            });
        }
        this.roleThumbnail.imgUrl = roleInfo.thumbnail;
        this.roleFace.imgUrl = roleInfo.face;
        this.roleBirthday = roleInfo.birthday;
        this.roleGender = roleInfo.gender;
        const roleStatusResult = await roleServices.getRoleStatus(this.roleId);
        this.roleApplicationStatus.code = roleStatusResult.return_code;
        switch (roleStatusResult.return_code) {
            case 0:
            case -1:
                this.applyButtonDisabled = false;
                this.applyButtonShow = true;
                break;
            case 1:
                this.applyButtonShow = false;
                this.revokeButtonShow = true;
                this.canEdit = false;
                break;
            case 2:
                this.applyButtonShow = true;
                this.applyButtonDisabled = true;
                this.canEdit = false;
                this.revokeApplyButtonShow = true;
                break;
            case 3:
                this.applyButtonDisabled = false;
                this.applyButtonShow = true;
                try {
                    const parsedValue = JSON.parse(roleStatusResult.message);
                    let reviewMessage = '';
                    for (const item in parsedValue) {
                        if (item == 'role') {
                            for (const roleItem in parsedValue[item]) {
                                if (parsedValue[item][roleItem]['status'] == '0') {
                                    if (reviewMessage.length > 0) {
                                        reviewMessage += '、';
                                    }
                                    reviewMessage += `${parsedValue[item][roleItem]['message']}`
                                }
                            }
                        } else if (parsedValue[item]['status'] == '0') {
                            if (reviewMessage.length > 0) {
                                reviewMessage += '、';
                            }
                            reviewMessage += `${parsedValue[item]['message']}`
                        }
                    }
                    this.roleApplicationStatus.message = reviewMessage;
                } catch (error) {
                    this.roleApplicationStatus.message = roleStatusResult.message;
                }

                break;
        }
        window.addEventListener("resize", this.windowResize);
        this.fetchEmotions();
    },
    mounted() {
    },
    methods: {
        disableLocale(selectedLocale) {
            const locale = this.locales.find(locale => locale.id == selectedLocale);
            locale.disabled = true;
            return locale.name;
        },
        enableLocale(selectedLocale) {
            const locale = this.locales.find(locale => locale.id == selectedLocale);
            locale.disabled = false;
        },
        addTitle() {
            let selectedLocale = this.localeSelector;

            if (selectedLocale == '') {
                return;
            }
            const locale = this.locales.find(locale => locale.id == selectedLocale);

            this.localeSelector = '';
            this.editTitle = {
                title: null,
                localeName: locale.name,
                locale: selectedLocale,
                description: null
            }
            this.editRoleTitleDialog = true;
        },
        openEditRoleTitleDialog(item) {
            this.editTitle = {
                id: item.id,
                title: item.title,
                localeName: item.localeName,
                locale: item.locale,
                description: item.description
            }
            this.editRoleTitleDialog = true;
        },
        async fetchEmotions() {
            ///Get each emotion's info
            for (let item of this.emotions) {
                const emotionInfo = await roleServices.getEmotion(this.roleId, item.id);
                if (emotionInfo.message && emotionInfo.message.includes('尚未設定此情緒')) {
                    continue;
                }
                item.thumbnailUrl = emotionInfo.thumbnail;
                item.imgUrl = emotionInfo.image.replace('/org/', '/small/');
                item.createdAt = emotionInfo.created_at;
                item.updatedAt = emotionInfo.updated_at;
                item.imageId = emotionInfo.image_id;
            }
        },
        async saveTitle() {
            if (this.$refs.roleTitleForm.validate()) {
                this.buttonLoading = true;
                if (this.editTitle.id != null) {
                    let titleItem = this.titles.find(title => title.id == this.editTitle.id);
                    titleItem.title = this.editTitle.title;
                    titleItem.description = this.editTitle.description;
                } else {
                    this.titles.push({
                        editOptionFab: false,
                        id: this.titles.length + 1,
                        title: this.editTitle.title,
                        locale: this.editTitle.locale,
                        localeName: this.editTitle.localeName,
                        description: this.editTitle.description
                    });
                    this.disableLocale(this.editTitle.locale);
                }
                const data = {
                    name: this.editTitle.title,
                    description: this.editTitle.description
                };
                await roleServices.updateRoleInfoLocale(this.roleId,
                    this.editTitle.locale, data);
                this.buttonLoading = false;
                this.editRoleTitleDialog = false;
            }
        },
        openEditBirthdayDialog() {
            this.editRoleBirthday = this.roleBirthday;
            this.editBirthdayDialog = true;
        },
        async saveBirthday() {
            this.buttonLoading = true;
            const data = {
                birthday: this.editRoleBirthday
            }
            await roleServices.updateRoleInfo(this.roleId, data);
            this.roleBirthday = this.editRoleBirthday;
            this.buttonLoading = false;
            this.editBirthdayDialog = false;
        },
        openEditGenderDialog() {
            this.selectedRoleGender = this.roleGender;
            this.editRoleGenderDialog = true;
        },
        async saveGender() {
            this.buttonLoading = true;
            const data = {
                gender: this.selectedRoleGender
            }
            await roleServices.updateRoleInfo(this.roleId, data);
            this.roleGender = this.selectedRoleGender;
            this.buttonLoading = false;
            this.editRoleGenderDialog = false;
        },
        getTypeText(type) {
            switch (type) {
                case 'birthday':
                    return '生日';
                default:
                    return '性別';
            }
        },
        openEditRoleThumbnailDialog() {
            this.editedRoleThumbnail.editThumbnailFiles = null;
            this.editRoleThumbnailDialog = true;
        },
        async saveRoleThumbnail() {
            if (this.$refs.thumbnailForm.validate()) {
                this.buttonLoading = true;
                // const result = await this.$getCropperImg(this.$refs.roleThumbnailCropper);
                const result = await this.$getCropperData(this.$refs.roleThumbnailCropper);
                if (result.status == 1) {
                    const postData = {
                        thumbnail_img: result.data
                    };
                    const updateResult = await roleServices.updateThumbnail(this.roleId, postData);
                    const randomNumber = ((Math.random() * 46656) | 0);
                    this.roleThumbnail.imgUrl = `${updateResult.avatar_url}?${randomNumber}`;
                    this.buttonLoading = false;
                    this.editRoleThumbnailDialog = false;
                }
            } else {
                console.log('validate fail');
            }
        },
        openEditRoleFaceDialog() {
            this.editRoleFace.editRoleFaceFile = null;
            this.editRoleFaceDialog = true;
        },
        async saveRoleFace() {
            if (this.$refs.thumbnailForm.validate()) {
                this.buttonLoading = true;
                // const result = await this.$getCropperImg(this.$refs.roleThumbnailCropper);
                const result = await this.$getCropperData(this.$refs.roleFaceCropper);
                if (result.status == 1) {
                    const postData = {
                        face_img: result.data
                    };
                    const updateResult = await roleServices.updateFace(this.roleId, postData);
                    const randomNumber = ((Math.random() * 46656) | 0);
                    this.roleFace.imgUrl = `${updateResult.face_url}?${randomNumber}`;
                    this.buttonLoading = false;
                    this.editRoleFaceDialog = false;
                }
            } else {
                console.log('validate fail');
            }
        },
        async apply() {
            this.buttonLoading = true;
            this.canEdit = false;
            const result = await roleServices.apply(this.roleId);
            switch (result.status) {
                case 0:
                    this.roleApplicationStatus = {
                        code: 2
                    }
                    this.applyButtonDisabled = true;
                    break;
                case -1:
                    this.roleApplicationStatus = {
                        code: 3
                    }
                    try {
                        console.log('result.message', result.message);
                        // const parsedValue = JSON.parse(result.message);
                        const parsedValue = result.message;
                        let reviewMessage = '';
                        for (const item in parsedValue) {
                            if (item == 'role') {
                                for (const roleItem in parsedValue[item]) {
                                    if (parsedValue[item][roleItem]['status'] == '0') {
                                        if (reviewMessage.length > 0) {
                                            reviewMessage += '、';
                                        }
                                        reviewMessage += `${parsedValue[item][roleItem]['message']}`
                                    }
                                }
                            } else if (parsedValue[item]['status'] == '0') {
                                if (reviewMessage.length > 0) {
                                    reviewMessage += '、';
                                }
                                reviewMessage += `${parsedValue[item]['message']}`
                            }
                        }
                        this.roleApplicationStatus.message = reviewMessage;
                    } catch (error) {
                        console.log('error:', error)
                        this.roleApplicationStatus.message = result.message;
                    }
                    break;
            }
            this.buttonLoading = false;
        },
        windowResize() {
            this.$cropperResize(this.$refs.roleThumbnailBlock, this.$refs.roleThumbnailCropper, this.roleThumbnailOption);
            this.$cropperResize(this.$refs.roleFaceBlock, this.$refs.roleFaceCropper, this.editRoleFaceOption);
            this.$cropperResize(this.$refs.editEmotionThumbnailBlock, this.$refs.editEmotionThumbnailCropper, this.editEmotionThumbnailOption);
            this.$cropperResize(this.$refs.editEmotionImgBlock, this.$refs.editEmotionImgCropper, this.editEmotionImgOption);
        },
        editEmotion(item) {
            this.editedItem = {
                emotionId: item.id,
                thumbnailUrl: item.thumbnailUrl,
                imgUrl: item.imgUrl,
                name: item.name
            }
            this.scale = 1;
            this.thumbnailScale = 1;
            this.emotionEditDialog = true;
        },
        saveEmotion() {
            if (this.$refs.emotionForm.validate()) {
                this.buttonLoading = true;
                // Promise.all([this.$getCropperImg(this.$refs.editEmotionThumbnailCropper),
                // this.$getCropperImg(this.$refs.editEmotionImgCropper)])
                Promise.all([this.$getCropperData(this.$refs.editEmotionThumbnailCropper),
                this.$getCropperData(this.$refs.editEmotionImgCropper)])
                    .then(async result => {
                        let thumbnailImageData = null, imageData = null;
                        if (result[0].status == 1) {
                            thumbnailImageData = result[0].data;
                        }
                        if (result[1].status == 1) {
                            imageData = result[1].data;
                        }
                        const postData = {
                            role_id: this.roleId,
                            emotion_id: this.editedItem.emotionId,
                            thumbnail: thumbnailImageData,
                            image: imageData
                        };
                        const updateResult = await roleServices.updateEmotionFile(postData);
                        const randomNumber = ((Math.random() * 46656) | 0);
                        this.editedItem.thumbnailUrl = `${updateResult.cover_image_url}?${randomNumber}`;
                        this.editedItem.imgUrl = `${updateResult.url}?${randomNumber}`;

                        let emotionItem = this.emotions.find(emotion => emotion.id == this.editedItem.emotionId);
                        if (emotionItem) {
                            const emotionInfo = await roleServices.getEmotion(this.roleId, emotionItem.id);
                            if (thumbnailImageData != null) {
                                emotionItem.thumbnailUrl = this.editedItem.thumbnailUrl;
                            }
                            if (imageData != null) {
                                emotionItem.imgUrl = this.editedItem.imgUrl;
                            }
                            emotionItem.createdAt = emotionInfo.created_at;
                            emotionItem.updatedAt = emotionInfo.updated_at;
                        }
                        this.buttonLoading = false;
                        this.emotionEditDialog = false;
                    });
            }
        },
        async revoke() {
            const result = await roleServices.revoke(this.roleId);
            if (result.return_code == '0306001') {
                this.applyButtonDisabled = false;
                this.applyButtonShow = true;
                this.revokeButtonShow = false;
                this.canEdit = true;
            }
        },
        async revokeApply() {
            console.log('revoke apply button tapped')
            this.buttonLoading = true;
            const result = await roleServices.revokeApply(this.roleId);
            if (result.return_code == '0305011') {
                this.applyButtonDisabled = false;
                this.applyButtonShow = true;
                this.canEdit = true;
                this.revokeApplyButtonShow = false;
            }
            this.buttonLoading = false;
        },
        previewEmotion(item) {
            this.previewItem = {
                imgId: item.imageId,
                emotionId: item.id,
                name: item.name,
                image: ''
            }
            this.fetchPreviewImage(item.imageId, item.id);
            this.previewEmotionDialog = true;
        },
        async fetchPreviewImage(imageId, emotionId) {
            const result = await roleServices.getPreviewRoleComposite(this.roleId,
                imageId, emotionId);
            this.previewItem.image = `data:image/png;base64,${result.img_base64_src}`;
        },
        /// Upload files in batch
        inputFilter: function (newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                // Filter non-image file
                if (!/\.(png)$/i.test(newFile.name)) {
                    return prevent()
                }
            }
            if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
                // Create a blob field
                newFile.blob = ''
                let URL = window.URL || window.webkitURL
                if (URL && URL.createObjectURL) {
                    newFile.blob = URL.createObjectURL(newFile.file)
                }
                // Thumbnails
                newFile.thumb = ''
                if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
                    newFile.thumb = newFile.blob
                }
            }
            // check image file size
            if (newFile && newFile.size > this.fileSizeLimit) {
                newFile.error = '檔案大小超過限制';
                newFile.uploadable = false;
            }
            // check image style
            let emotion = '-1';
            let emotionItem;
            let existedFile = null;
            if (newFile) {
                const name = newFile.name;
                this.emotions.forEach(item => {
                    if (name.includes(item.name)) {
                        emotion = item.id;
                        newFile.emotionId = item.id;
                        newFile.emotion = item.name;
                    }
                });
                if (emotion == '-1') {
                    if (newFile.error === '') {
                        newFile.error += '無法辨識情緒';
                    } else {
                        newFile.error += '、無法辨識情緒';
                    }
                    newFile.uploadable = false;
                } else {
                    emotionItem = this.batchEmotions.find(item => item.id == emotion);
                    if (emotionItem) {
                        if (name.includes('_icon')) {
                            if (emotionItem.thumbnailId !== '') {
                                existedFile = this.files.find(file => file.id == emotionItem.thumbnailId);
                            }

                            emotionItem.thumbnailId = newFile.id;
                            newFile.style = '情緒縮圖';
                        } else {
                            if (emotionItem.imgId !== '') {
                                existedFile = this.files.find(file => file.id == emotionItem.imgId);
                            }

                            emotionItem.imgId = newFile.id;
                            newFile.style = '情緒大圖';
                        }
                    }
                }
            }

            if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
                newFile.error = 'image parsing'
                let img = new Image();
                img.onload = () => {
                    newFile.error = '';
                    if (newFile.style === '情緒縮圖' && img.width != img.height) {
                        if (newFile.error === '') {
                            newFile.error += '縮圖寬高比須為1:1';
                        } else {
                            newFile.error += '、縮圖寬高比須為1:1';
                        }
                        newFile.uploadable = false;
                    } else if (newFile.style === '情緒大圖' && img.height != img.width * 1.2) {
                        if (newFile.error === '') {
                            newFile.error += '情緒大圖寬高比須為1:1.2';
                        } else {
                            newFile.error += '、情緒大圖寬高比須為1:1.2';
                        }
                        newFile.uploadable = false;
                    } else {
                        newFile.uploadable = true;
                    }
                    this.$refs.upload.update(newFile, { error: newFile.error, height: img.height, width: img.width })
                }
                img.onerror = (e) => {
                    console.log('error on new img:', e)
                    this.$refs.upload.update(newFile, { error: 'parsing image size' })
                }
                img.src = newFile.blob
                // newFile.uploadable = true;
            }

            /// 檢查/更新相同情緒的縮圖或大圖狀態
            if (emotionItem && emotionItem.thumbnailId === '') {
                emotionItem.uploadable = false;
                newFile.uploadable = false;
                newFile.emotionError = '沒有情緒縮圖';
            } else if (emotionItem && emotionItem.imgId === '') {
                emotionItem.uploadable = false;
                newFile.uploadable = false;
                newFile.emotionError = '沒有情緒大圖';
            } else if (emotionItem && newFile.error === '') {
                if (newFile.style == '情緒縮圖') {
                    this.files.find((imageFile) => {
                        if (imageFile.id == emotionItem.imgId) {
                            imageFile.emotionError = '';
                            if (imageFile.error === '') {
                                imageFile.uploadable = true;
                                emotionItem.uploadable = true;
                                newFile.uploadable = true;
                            } else {
                                imageFile.uploadable = false;
                                emotionItem.uploadable = false;
                                newFile.uploadable = false;
                                newFile.emotionError = '情緒大圖錯誤：' + imageFile.error;
                            }
                        }
                    });
                } else {
                    this.files.find((thumbnailFile) => {
                        if (thumbnailFile.id == emotionItem.thumbnailId) {
                            thumbnailFile.emotionError = '';
                            if (thumbnailFile.error === '') {
                                thumbnailFile.uploadable = true;
                                emotionItem.uploadable = true;
                                newFile.uploadable = true;
                            } else {
                                thumbnailFile.uploadable = false;
                                emotionItem.uploadable = false;
                                newFile.uploadable = false;
                                newFile.emotionError = '情緒縮圖錯誤：' + thumbnailFile.error;
                            }
                        }
                    });
                }
            }
            ///有錯誤的話更新已有的縮圖或大圖狀態
            if (emotionItem && newFile.error !== '') {
                if (newFile.style == '情緒縮圖') {
                    this.files.find((imageFile) => {
                        if (imageFile.id == emotionItem.imgId) {
                            imageFile.emotionError = '情緒縮圖錯誤：' + newFile.error;
                            imageFile.uploadable = false;
                            emotionItem.uploadable = false;
                        }
                    });
                } else {
                    this.files.find((thumbnailFile) => {
                        if (thumbnailFile.id == emotionItem.thumbnailId) {
                            thumbnailFile.emotionError = '情緒大圖錯誤：' + newFile.error;
                            thumbnailFile.uploadable = false;
                            emotionItem.uploadable = false;
                        }
                    });
                }
            }
            if (existedFile) {
                if (existedFile.id != newFile.id) {
                    this.files.splice(this.files.indexOf(existedFile), 1);
                }
            }
        },
        // add, update, remove File Event
        inputFile: function (newFile, oldFile) {
            if (!newFile && oldFile) {
                // Remove item in batchEmotions and check related item
                let emotionItem = this.batchEmotions.find(item => item.id == oldFile.emotionId);
                emotionItem.uploadable = false;
                if (oldFile.style === '情緒縮圖') {
                    emotionItem.thumbnailId = '';
                    if (emotionItem.imgId !== '') {
                        this.files.find((file) => {
                            if (file.id == emotionItem.imgId) {
                                file.uploadable = false;
                                file.emotionError = '沒有情緒縮圖';
                            }
                        });
                    }
                } else {
                    emotionItem.imgId = '';
                    if (emotionItem.thumbnailId !== '') {
                        this.files.find((file) => {
                            if (file.id == emotionItem.thumbnailId) {
                                file.uploadable = false;
                                file.emotionError = '沒有情緒大圖';
                            }
                        });
                    }
                }
            }
            if (newFile && oldFile && !newFile.active && oldFile.active) {
                // Get response data
                console.log('response', newFile.response)
                if (newFile.xhr) {
                    //  Get the response status code
                    console.log('status', newFile.xhr.status)
                }
            }
        },
        onAddFolder() {
            if (!this.$refs.upload.features.directory) {
                this.alert('Your browser does not support')
                return
            }
            let input = document.createElement('input')
            input.style = "background: rgba(255, 255, 255, 0);overflow: hidden;position: fixed;width: 1px;height: 1px;z-index: -1;opacity: 0;"
            input.type = 'file'
            input.setAttribute('allowdirs', true)
            input.setAttribute('directory', true)
            input.setAttribute('webkitdirectory', true)
            input.multiple = true
            document.querySelector("body").appendChild(input)
            input.click()
            input.onchange = () => {
                this.$refs.upload.addInputFile(input).then(function () {
                    document.querySelector("body").removeChild(input)
                })
            }
        },
        formatSize(size) {
            if (size > 1024 * 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
            } else if (size > 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
            } else if (size > 1024 * 1024) {
                return (size / 1024 / 1024).toFixed(2) + ' MB'
            } else if (size > 1024) {
                return (size / 1024).toFixed(2) + ' KB'
            }
            return size.toString() + ' B'
        },
        async uploadFiles() {
            this.batchFilesUploading = true;
            try {
                this.batchEmotionToUpload = [];
                this.batchEmotions.forEach(item => {
                    if (item.uploadable) {
                        this.batchEmotionToUpload.push(item);
                    }
                });
                this.batchTotal = this.batchEmotionToUpload.length;
                this.batchCompleted = 0;
                for (let item of this.batchEmotionToUpload) {
                    this.batchFilesUploadingValue = Math.round((this.batchCompleted / this.batchTotal) * 100) - 5;
                    this.batchFilesUploadingBufferValue = Math.round(((this.batchCompleted + 1) / this.batchTotal) * 100) - 5;
                    const thumbnailFile = this.files.find(file => file.id == item.thumbnailId);
                    const imageFile = this.files.find(file => file.id == item.imgId);
                    const thumbnailBase64 = await this.blobToBase64(thumbnailFile.file);
                    const imageBase64 = await this.blobToBase64(imageFile.file);
                    const postData = {
                        role_id: this.roleId,
                        emotion_id: item.id,
                        thumbnail: thumbnailBase64,
                        image: imageBase64
                    };
                    const result = await roleServices.updateEmotionFile(postData);
                    let updatedEmotion = this.emotions.find(emotion => emotion.id == item.id);
                    const randomNumber = ((Math.random() * 46656) | 0);
                    updatedEmotion.thumbnailUrl = `${result.cover_image_url}?${randomNumber}`;
                    updatedEmotion.imgUrl = `${result.url}?${randomNumber}`;

                    this.batchCompleted += 1;
                    /// Remove uploaded file in files
                    this.files.splice(this.files.indexOf(thumbnailFile), 1);
                    this.files.splice(this.files.indexOf(imageFile), 1);

                    /// Update emotion record in batchEmotions
                    let emotionRecord = this.batchEmotions.find(emotion => emotion.id == item.id);
                    emotionRecord.thumbnailId = '';
                    emotionRecord.imgId = '';
                    emotionRecord.uploadable = false;
                }
            } catch (error) {
                console.log('error', error);
            }
            this.batchTotal = 0;
            this.batchCompleted = 0;
            this.batchFilesUploading = false;
            this.fetchEmotions();
        },
        blobToBase64(blob) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(blob);
            });
        },
        refreshCropper() {
            this.$refs.editEmotionImgCropper.refresh();
        },
        refreshThumbnailCropper() {
            this.$refs.editEmotionThumbnailCropper.refresh();
        },
    },
    watch: {
        scale(newVal, oldVal) {
            if (!this.$refs.editEmotionImgCropper) {
                return;
            }
            this.$refs.editEmotionImgCropper.changeScale((newVal - oldVal));
        },
        thumbnailScale(newVal, oldVal) {

            console.log('thumbnailScale', newVal, oldVal);

            if (!this.$refs.editEmotionThumbnailCropper) {
                return;
            }
            this.$refs.editEmotionThumbnailCropper.changeScale((newVal - oldVal));
        }
    }

};
</script>

<style scoped>
.role-thumbnail {
    width: 70px;
}

.batch-thumbnail {
    width: 80px;
}

.commonColor {
    color: #424242;
}

.emo-img-block {
    width: 200;
    /* background-repeat: repeat; */
}

.emo-img-block>div {

    /* margin: 30px auto;
    aspect-ratio: 1; */
    /* height: 100%; */
    background-repeat: repeat;
    aspect-ratio: 5 / 6;
}

.preview-image {
    width: 320px;
}

@media (min-width: 600px) {
    .emo-img-block {
        width: 240px;
    }
}

@media (min-width: 960px) {
    .emo-img-block {
        width: 300px;
    }
}

@media (min-width: 1264px) {
    .emo-img-block {
        width: 360;
    }
}

.edit-img-container {
    position: relative;
    /* Needed for absolutely positioning the lines */
}

.reference-line {
    position: absolute;
    background-color: darksalmon;
    /* You can change the color of the lines */
    z-index: 10;
    /* Ensure lines are on top of the image */
}

.horizontal-line {
    left: 0;
    right: 0;
    height: 0.5px;
}

.vertical-line {
    top: 0;
    bottom: 0;
    width: 0.5px;
}

.first-line {
    /* top: 50px; */
    top: 16.67%;
    /* Position of the first line */
}

.second-line {
    /* top: 133px; */
    top: 44.44%;
    /* Position of the second line */
}

.face-line {
    top: 37.5%;
    background-color: darkseagreen;
}

.base-line {
    top: 76.92%;
    background-color: darkseagreen;
}

.first-ver-line {
    /* left: 83px; */
    left: 33.3%;
    /* Position of the first line */
}

.second-ver-line {
    /* left: 162px; */
    left: 66.6%;
    /* Position of the second line */
}
</style>