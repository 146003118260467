<template>
    <v-app>
        <v-container>
            <v-row class="justify-center align-center" style="height: 85vh">
                <v-col class="col-12 col-sm-8 col-md-6 col-lg-4">
                    <h1 class="mb-2 text-center">管理平臺</h1>
                    <v-alert type="error" outlined v-show="loginError">
                        帳號或密碼錯誤
                    </v-alert>
                    <div class="text-center">
                    <v-btn
                        class="subtitle-1 mt-3 mr-2 ml-2"
                        color="deep-orange darken-1"
                        outlined
                        @click="mangaChatLogin">
                        <span class="login_text">登入</span>
                    </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
// import { loginServices } from "@/services/adminLogin.js";
import { loginServices as userLoginServices } from '@/services/login.js';

export default {
    name: "Login",
    components: {},
    data() {
        return {
            loginError: false,
            valid: true,
            showPwd: false,
            accountRules: {
                required: (v) => !!v || "必須填寫帳號",
            },
            pwdRules: [(v) => !!v || "必須填寫密碼"],
            account: "",
            password: "",
            isLoginLoading: false,
        };
    },

    async created() {
        //localStorage.clear();
    },
    methods: {
        async mangaChatLogin() {
            userLoginServices.login();
        },
    },
};
</script>

<style scoped>
.login_text {
    color: #424242;
}
</style>