import serviceUtils from "../utils/service";

export const reviewServices = {
    async getReviewRoleList() {
        try {
            const url = `${serviceUtils.resourceUrl('reviewRoleList')}`;
            const response = await serviceUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to getReviewRoleList err: ${err}`);
            throw err
        }
    },
    async getRoleApplicationId(id) {
        try {
            const url = `${serviceUtils.resourceUrl('reviewRoleApplication')}`
                .replace(':id', id);
            const response = await serviceUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to getReviewRoleInfo err: ${err}`);
            throw err
        }
    },
    async sendRoleReviewResult(id, payload) {
        try {
            const url = `${serviceUtils.resourceUrl('reviewRoleApplication')}`
                .replace(':id', id);
            const response = await serviceUtils.post(url, payload);
            return response.data;
        } catch (err) {
            console.log(`failed to sendRoleReviewResult err: ${err}`);
            throw err
        }
    },
    async addRoleToShop(payload) {
        try {
            const url = `${serviceUtils.resourceUrl('shopAddRole')}`;
            const response = await serviceUtils.post(url, payload);
            return response.data;
        } catch (err) {
            console.log(`failed to updateRoleShop err: ${err}`);
            throw err
        }
    },
    async updateRoleShop(payload) {
        try {
            const url = `${serviceUtils.resourceUrl('shopUpdateRole')}`;
            const response = await serviceUtils.post(url, payload);
            return response.data;
        } catch (err) {
            console.log(`failed to updateRoleShop err: ${err}`);
            throw err
        }
    },
    async getRoleShop(id) {
        try {
            const url = `${serviceUtils.resourceUrl('shopGetRole')}`
            .replace(':id', id);
            const response = await serviceUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to updateRoleShop err: ${err}`);
            throw err
        }
    },
    async getReviewImageList(type) {
        try {
            const url = `${serviceUtils.resourceUrl('reviewImageList')}`
                .replace(':type', type);
            const response = await serviceUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to getReviewImageList err: ${err}`);
            throw err
        }
    },
    async getReviewImageDetail(id) {
        try {
            const url = `${serviceUtils.resourceUrl('reviewImageDetail')}`
                .replace(':id', id);
            const response = await serviceUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to getReviewImageDetail err: ${err}`);
            throw err
        }
    },
    async sendReviewImageResult(id, payload) {
        try {
            const url = `${serviceUtils.resourceUrl('reviewImage')}`
                .replace(':id', id);
            const response = await serviceUtils.post(url, payload);
            return response.data;
        } catch (err) {
            console.log(`failed to sendReviewImageResult err: ${err}`);
            throw err
        }
    },
    async getArtist(id) {
        try {
            const url = `${serviceUtils.resourceUrl('reviewGetArtist')}`
                .replace(':id', id);
            const response = await serviceUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to getArtist err: ${err}`);
            throw err
        }
    }
}