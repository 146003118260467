<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>

        <v-card outlined>
            <v-data-table
                :headers="headers"
                :items="themes"
                :server-items-length="totalThemes"
                :loading="loading"
                :page.sync="themePage"
                :options.sync="themeOptions"
                hide-default-footer>
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-3" outlined small @click="newTheme()">
                            <v-icon small>mdi-plus</v-icon> 新增主題
                        </v-btn>
                    </v-toolbar>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-btn small class="mr-2 mt-1" outlined @click="goThemeDetail(item.id)">
                        <v-icon small class="mr-1"> mdi-eye </v-icon>
                        查看
                    </v-btn>
                </template>
            </v-data-table>
            <div class="text-center pt-2">
                <v-pagination
                    v-model="themePage"
                    :length="themePageCount"
                    circle
                    color="orange accent-2">
                </v-pagination>
            </div>
        </v-card>
        <v-dialog v-model="addDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">新增主題</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="emotionForm" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="4" class="grey lighten-4">
                                    <v-subheader>語言</v-subheader>
                                </v-col>
                                <v-col cols="8" class="pa-2">
                                    中文
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" class="grey lighten-4">
                                    <v-subheader>主題名稱</v-subheader>
                                </v-col>
                                <v-col cols="8" class="pa-2">
                                    <v-text-field label="名稱" v-model="themeName" single-line dense
                                        hide-details="auto"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="addDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveTheme" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { themeServices } from "@/services/theme.js";
export default {
    name: "themeInfo",
    components: {
    },
    data() {
        return {
            breadcrumbsItems: [],
            addDialog: false,
            buttonLoading: false,
            infoMode: 'show',
            themeName: '',
            logoFiles: null,
            bannerFiles: null,
            scale: 0,
            lastScale: 0,
            option: this.$cropperOption,
            logoOption: {
                img: '',
                fixedNumber: [3, 1],
                autoCropHeight: 150,
                autoCropWidth: 450,
            },
            bannerOption: {
                img: '',
                fixedNumber: [4, 1],
                autoCropHeight: 150,
                autoCropWidth: 600,
            },

            headers: [

                {
                    text: '主題名稱',
                    value: 'title',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '主題ID',
                    value: 'id',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '建立日期',
                    value: 'createdAt',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '修改日期',
                    value: 'updatedAt',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '功能', value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
            ],
            themes: [],
            totalThemes: 0,
            themePage: 1,
            themePageCount: 1,
            loading: false,
            themeOptions: {}
        };
    },
    async created() {
        this.breadcrumbsItems.push({
            text: '主題管理',
            disabled: true,
            href: ``,
        });
    },
    mounted() {
    },
    watch: {
        themeOptions: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    methods: {
        getDataFromApi() {
            this.loading = true;
            this.getThemeList().then(data => {
                this.themes = data.items;
                this.totalThemes = data.total;
                this.themePageCount = data.pageCount;
                this.loading = false;
                this.themePage = data.page;
            })
        },
        async getThemeList() {
            const { page } = this.themeOptions;
            const res = await themeServices.getList(page);
            const items = res.data.map(function (d) {
                return {
                    title: d.title,
                    id: d.id,
                    createdAt: d.create_date,
                    updatedAt: d.update_date,
                };
            });
            const total = res.total;
            const pageCount = res.totalPage;
            return { items, total, pageCount, page }
        },
        newTheme() {
            this.themeName = '';
            this.addDialog = true;
        },
        async saveTheme() {
            const data = {
                title: this.themeName,
                locale: 'zh'
            };
            const responseData = await themeServices.addTheme(data);
            this.themes.push({
                title: this.themeName,
                id: responseData.theme_id,
                createdAt: responseData.create_date,
                updatedAt: responseData.update_date
            })
            this.addDialog = false;
        },
        goThemeDetail(id) {
            this.$router.push(`/console/theme/${id}`);
        }
    },
};
</script>

<style scoped></style>