import servicesUtils from '../utils/service'

export const loginServices = {
    // async login(d) {
    //     const url = `${servicesUtils.resourceUrl('login')}`
    //     const data = await servicesUtils.post(url, d)
    //     return data
    // },
    // async getUserInfo() {
    //     const url = `${servicesUtils.resourceUrl('userName')}`
    //     const data = await servicesUtils.get(url, {})
    //     return data
    // },
    // async getAdminName() {
    //     const url = `${servicesUtils.resourceUrl('adminName')}`
    //     const data = await servicesUtils.get(url, {})
    //     return data
    // },
    async updatePwd(oldPwd, newPwd) {
        const url = `${servicesUtils.resourceUrl('changePwd')}`
        const data = await servicesUtils.post(url, {
            old_pwd: oldPwd,
            new_pwd: newPwd,
        })
        return data
    },

    /*
     * Create form to request access code from MangaChat server.
     */
    async login() {
        try {
            const url = `${servicesUtils.resourceUrl('loginLink')}?redirect_uri=${process.env.VUE_APP_LOGIN_REDIRECT_URL}`
            const response = await servicesUtils.get(url);
            const oauthLink = response.link;
            location.href = oauthLink;
        } catch (err) {
            console.log(`failed to login err: ${err}`);
            throw err
        }
    },
    async getUserInfo(data) {
        try {
            const url = `${servicesUtils.resourceUrl('userInfo')}`
            const response = await servicesUtils.post(url, data);
            return response.data;
        } catch (err) {
            console.log(`failed to getUserInfo err: ${err}`);
            throw err
        }
    },
    async logout() {
        const url = `${servicesUtils.resourceUrl('userLogout')}`
        
        console.log('url:', url);

        const data = await servicesUtils.post(url, {})
        return data
    },
    // async getUserMenu() {
    //     try {
    //         const url = `${servicesUtils.resourceUrl('userMenu')}`
    //         const response = await servicesUtils.get(url);

    //         console.log('loginservice.getUserMenu response:', response);

    //         return response.data;
    //     } catch (err) {
    //         console.log(`failed to getUserMenu err: ${err}`);
    //         throw err
    //     }
    // },
}