<template>
    <div class="main-content error-page row no-gutters justify-center mt-4">
        <div class="col-xs-12 align-center justify-center row no-gutters text-left px-5">
            <v-alert
            outlined
            type="warning"
            prominent
            border="left"
            >
            <span class="text-h6">Oooops~系統發生異常，請聯絡漫話科技客服</span><br>
            <span class="text-h6">聯絡資訊</span><br>
            <span>客服電話：+886-979-357-131</span><br>
            <span>週一至週五 10:00~17:00（臺灣時間）(Weekday 10:00~17:00)</span><br>
            <span>Line客服專線：</span><span>https://lin.ee/v446Bup</span><br>
            <span>信箱email：</span><a href="mailto: mangaxtechnology@gmail.com" target="_blank">mangaxtechnology@gmail.com</a>
            </v-alert>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ErrorPage'
}
</script>