<template>
    <v-container>
        <h3>基本資料</h3>
        <v-card outlined>
            <v-card flat class="border-bottom" v-for="input in infoInputs" :key="input.id">
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>語言</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        {{ input.localeName }}
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>姓名</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        {{ input.name }}
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>描述</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        {{ input.intro }}
                    </v-col>
                </v-row>
            </v-card>
            <v-card flat class="border-bottom">
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>大頭照</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        <v-img class="thumbnail-block" :src="thumbnail.imgUrl" v-if="thumbnail.imgUrl"></v-img>
                    </v-col>
                </v-row>
            </v-card>
            <v-card flat class="border-bottom">
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>頁面圖</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        <v-img class="cover-block" :src="cover.imgUrl" v-if="cover.imgUrl"></v-img>
                        <span v-if="!cover.imgUrl">尚未設定</span>
                    </v-col>
                </v-row>
            </v-card>
        </v-card>
        <h3 class="mt-2">社群平台</h3>
        <v-card outlined>
            <v-card flat class="border-bottom" v-for="input in socialMediaInputs" :key="input.id">
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>社群平台</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        {{ input.typeName }}
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>網址</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        {{ input.url }}
                    </v-col>
                </v-row>
            </v-card>
        <v-card v-if="socialMediaInputs.length==0">
            <v-card-text>
                <span>尚未設定</span>
            </v-card-text>
        </v-card>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "artistIntro",
    components: {},
    props: ['artistInfo'],
    data() {
        return {
            infoLocales: [{
                id: 'zh',
                name: '中文',
                disabled: false
            }, {
                id: 'en',
                name: '英文',
                disabled: false
            }],
            socialMedias: [{
                id: 'fb',
                name: 'Facebook'
            }, {
                id: 'ig',
                name: 'Instagram'
            }, {
                id: 'web',
                name: '網站'
            }],
            infoInputs: [],
            socialMediaInputs: [],
            thumbnail: {},
            cover: {},
        }
    },
    created() {
        const infoList = this.artistInfo.basic_info_list;
        let id = 0;
        infoList.forEach(info => {
            let localeName = this.infoLocales.find(locale => locale.id == info.locale).name;
            this.infoInputs.push({
                editOptionFab: false,
                id: id,
                locale: info.locale,
                localeName: localeName,
                name: info.name,
                intro: info.intro,
            });
            id += 1;
        });
        this.thumbnail.imgUrl = this.artistInfo.thumbnail;
        this.cover.imgUrl = this.artistInfo.cover;
        const socialMediaList = this.artistInfo.social_media_list;
        id = 0;
        socialMediaList.forEach(socialMedia => {
            let typeName = this.socialMedias.find(theSocialMedia => theSocialMedia.id == socialMedia.type).name;
            this.socialMediaInputs.push({
                id: id,
                type: socialMedia.type,
                typeName: typeName,
                url: socialMedia.url,
            });
            id += 1;
        });
    },

}
</script>

<style>
.cover-block {
    width: 240px;
}

.cover-block>div {
    aspect-ratio: 32 / 15;
}

@media (min-width: 600px) {
    .cover-block {
        width: 400px;
    }
}

@media (min-width: 960px) {
    .cover-block {
        width: 440px;
    }
}

@media (min-width: 1264px) {
    .cover-block {
        width: 440px !important;
    }
}
</style>