<template>
    <v-app>
        <v-container>
            <router-view :key="$route.path"></router-view>
        </v-container>
    </v-app>
</template>

<script>
export default {
    name: "AppDefault",

    components: {},

    data: () => ({
        //
    }),
};
</script>

<style>
</style>