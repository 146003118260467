<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>
        <v-row no-gutters>
            <v-col>
                <v-tabs v-model="tab">
                    <v-tabs-slider color="deep-orange arken-1"></v-tabs-slider>

                    <v-tab href="#tabInfo" class="grey--text text--darken-2 subtitle-1">
                        基本資料
                    </v-tab>
                    <v-tab href="#tabEmotion" class="grey--text text--darken-2 subtitle-1">
                        情緒列表
                    </v-tab>
                    <v-tab href="#tabArtist" class="grey--text text--darken-2 subtitle-1">
                        創作者資訊
                    </v-tab>
                    <v-tab href="#tabTheme" class="grey--text text--darken-2 subtitle-1">
                        主題資訊
                    </v-tab>
                </v-tabs>
            </v-col>
            <v-col no-gutters v-if="status == 0">
                <div>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            color="success" small
                            @click="openPriceDialog">
                            通過
                        </v-btn>
                        <v-btn color="error" small
                            @click="openReviewDialog()">
                            拒絕
                        </v-btn>
                    </v-toolbar>
                </div>
            </v-col>
            <v-col no-gutters v-if="status == 1">
                <div>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            color="success" small
                            @click="openPriceDialog">
                            修改商店設定
                        </v-btn>
                    </v-toolbar>
                </div>
            </v-col>
        </v-row>

        <v-tabs-items v-model="tab" class="mt-2">
            <v-tab-item value="tabInfo">
                <v-card outlined>
                    <v-card flat class="border-bottom" v-if="noTitle">
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>角色名稱</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                尚未設定
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat class="border-bottom" v-for="input in titles" :key="input.id">
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>語言</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                {{ input.localeName }}

                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>角色名稱</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                {{ input.title }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>角色敘述</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                {{ input.description }}
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat class="border-bottom">
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>生日</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                {{ roleBirthday }}
                                <span v-if="!roleBirthday">尚未設定</span>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat class="border-bottom">
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>性別</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                <span v-if="!roleGender">尚未設定</span>
                                <span v-else>{{ roleGender == 'm' ? '男性' : '女性' }}</span>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat class="border-bottom">
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>Logo</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                <v-img class="thumbnail-block" :src="roleThumbnail.imgUrl"
                                    v-if="roleThumbnail.imgUrl"></v-img>
                                <span v-if="!roleThumbnail.imgUrl">尚未設定</span>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat class="border-bottom">
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>Face</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                <v-img class="thumbnail-block" :src="roleFace.imgUrl" v-if="roleFace.imgUrl"></v-img>
                                <span v-if="!roleFace.imgUrl">尚未設定</span>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tabEmotion">
                <v-card outlined class="py-3 px-5">
                    <v-data-table
                        :headers="headers"
                        :items="emotions"
                        :items-per-page="-1"
                        disable-sort
                        :loading="loadingEmotions">
                        <template v-slot:item.thumbnailUrl="{ item }">
                            <v-img v-if="item.thumbnailUrl != null"
                                :src="item.thumbnailUrl" alt="情緒縮圖"
                                class="role-thumbnail mx-auto my-2">
                            </v-img>
                            <span v-if="!item.thumbnailUrl">尚未設定</span>
                        </template>

                        <template v-slot:item.imgUrl="{ item }">
                            <v-img v-if="item.imgUrl != null" :src="item.imgUrl"
                                alt="情緒大圖" class="role-thumbnail  mx-auto my-2">
                            </v-img>
                            <span v-if="!item.imgUrl">尚未設定</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn small class="mr-2 mt-1" outlined @click="previewEmotion(item)"
                                v-if="item.imgUrl != null">
                                <v-icon small class="mr-1"> mdi-eye </v-icon>
                                預覽
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tabArtist">
                <ArtistIntro v-if="artistLoaded" v-bind:artist-info="artistInfo"></ArtistIntro>
            </v-tab-item>
            <v-tab-item value="tabTheme">
                <ThemeIntro v-if="themeLoaded" v-bind:theme-info="themeInfo"></ThemeIntro>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog v-model="reviewDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">拒絕角色申請</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="reviewForm">
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>拒絕原因</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-text-field label="" v-model="reviewMessage"
                                        :rules="reviewMessageRules" dense hide-details="auto"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="reviewDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveReview" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="priceDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">商店細項設定</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="priceForm">
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>等級需求</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-select
                                        :items="levelItems"
                                        v-model="priceSetting.level"></v-select>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>兌換糖果數</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-select
                                        :items="candyItems"
                                        v-model="priceSetting.candy"></v-select>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>兌換寶石數</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-select
                                        :items="gemItems"
                                        v-model="priceSetting.gem"></v-select>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="priceDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="approve" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="previewEmotionDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">情緒預覽 - {{ previewItem.name }}</span>
                </v-card-title>
                <v-card-text>
                    <v-img class="preview-image mx-auto" :src="previewItem.image"
                        v-if="previewItem.image">
                    </v-img>
                    <v-row v-if="!previewItem.image">
                        <v-spacer></v-spacer>
                        <v-progress-circular indeterminate></v-progress-circular>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-3" outlined small
                        @click="previewEmotionDialog = false">
                        結束預覽
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { roleServices } from '@/services/role.js';
import { reviewServices } from '@/services/review.js';
import { themeServices } from '@/services/theme.js';
import ArtistIntro from '@/components/console/artist/ArtistIntro.vue';
import ThemeIntro from '@/components/console/theme/ThemeIntro.vue';

export default {
    name: "roleInfo",
    components: {
        ArtistIntro,
        ThemeIntro
    },
    data() {
        return {
            applicationId: '',
            roleId: '',
            status: -1,
            breadcrumbsItems: [],
            tab: null,
            infoMode: 'show',
            localeSelector: '',
            locales: [{
                id: 'zh',
                name: '中文',
                disabled: false
            }, {
                id: 'en',
                name: '英文',
                disabled: false
            }],
            titles: [],
            roleThumbnail: {
                editOptionFab: false,
                imgUrl: '',
            },
            roleFace: {
                editOptionFab: false,
                imgUrl: '',
            },
            roleBirthday: '',
            roleGender: '',
            headers: [
                {
                    text: '情緒名稱', value: 'name',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '縮圖',
                    sortable: false,
                    value: 'thumbnailUrl',
                    align: 'center',
                    class: 'grey lighten-4',
                }, {
                    text: '情緒大圖',
                    sortable: false,
                    value: 'imgUrl',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '建立日期',
                    value: 'createdAt',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '修改日期',
                    value: 'updatedAt',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '功能', value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
            ],
            emotions: [{
                id: '3',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.13',
                name: '快樂',
                type: 'like',
            },
            {
                id: '4',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.15',
                name: '得意',
                type: 'like',
            },

            {
                id: '5',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.14',
                name: '輕鬆',
                type: 'like',
            },

            {
                id: '6',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.9',
                name: '幸福',
                type: 'like',
            },

            {
                id: '7',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.2',
                name: '興奮',
                type: '',
            },

            {
                id: '8',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.1',
                name: '平靜',
                type: '',
            },

            {
                id: '9',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.16',
                name: '驚訝',
                type: '',
            },

            {
                id: 'A',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.19',
                name: '害怕',
                type: 'hate',
            },

            {
                id: 'B',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.20',
                name: '難過',
                type: 'hate',
            },

            {
                id: 'C',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.10',
                name: '愧疚',
                type: 'hate',
            },

            {
                id: 'D',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.7',
                name: '挫折',
                type: 'hate',
            },

            {
                id: 'E',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.12',
                name: '丟臉',
                type: 'hate',
            },

            {
                id: 'F',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.18',
                name: '緊張',
                type: 'hate',
            },

            {
                id: '11',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.8',
                name: '失望',
                type: 'hate',
            },

            {
                id: '12',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.4',
                name: '焦慮',
                type: 'hate',
            },

            {
                id: '13',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.5',
                name: '擔心',
                type: 'hate',
            },

            {
                id: '14',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.6',
                name: '無助',
                type: 'hate',
            },

            {
                id: '15',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.3',
                name: '嫉妒',
                type: 'hate',
            },

            {
                id: '16',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.11',
                name: '厭煩',
                type: 'hate',
            },

            {
                id: '17',
                thumbnailUrl: '',
                imgUrl: '',
                msgCode: 'diary.emotion.17',
                name: '生氣',
                type: 'hate',
            }],
            noTitle: false,
            reviewDialog: false,
            buttonLoading: false,
            reviewMessage: '',
            reviewMessageRules: [(v) => !!v || '請輸入審核意見'],
            priceDialog: false,
            priceSetting: {
                level: 3,
                candy: 10,
                gem: 1
            },
            levelItems: [1, 2, 3, 4, 5],
            candyItems: [10, 20, 40, 100],
            gemItems: [1, 2],
            previewItem: {
                imgId: '',
                emotionId: '',
                name: ''
            },
            previewEmotionDialog: false,
            loadingEmotions: true,
            artistLoaded: false,
            artistInfo: {},
            themeLoaded: false,
            themeInfo: {},
        };
    },
    async created() {
        this.breadcrumbsItems.push({
            text: '角色詳情',
            disabled: true,
            href: ``,
        });
        this.applicationId = this.$route.params.id;
        const applicationInfo = await reviewServices.getRoleApplicationId(this.applicationId);
        this.roleId = applicationInfo.manga_role_id;
        this.status = applicationInfo.status;
        if (applicationInfo.status == 1) {
            const shopDetail = await reviewServices.getRoleShop(this.roleId);
            this.priceSetting.level = shopDetail.level;
            this.priceSetting.candy = shopDetail.candy;
            this.priceSetting.gem = shopDetail.gem;
        }
        const roleInfo = await roleServices.getInfo(this.roleId);
        if (roleInfo.names) {
            let i = 1;
            roleInfo.names.forEach(item => {
                let localeName = this.disableLocale(item.locale);
                this.titles.push({
                    editOptionFab: false,
                    id: i,
                    title: item.name,
                    locale: item.locale,
                    localeName: localeName,
                    description: item.description
                });
                i += 1;
            });
            this.noTitle = false;
        } else {
            this.noTitle = true;
        }
        this.roleThumbnail.imgUrl = roleInfo.thumbnail;
        this.roleFace.imgUrl = roleInfo.face;
        this.roleBirthday = roleInfo.birthday;
        this.roleGender = roleInfo.gender;
        window.addEventListener("resize", this.windowResize);

        this.loadArtistInfo(roleInfo.creator);
        this.loadThemeInfo(roleInfo.theme);

        ///Get each emotion's info
        for (let item of this.emotions) {
            const emotionInfo = await roleServices.getEmotion(this.roleId, item.id);
            item.thumbnailUrl = emotionInfo.thumbnail;
            item.imgUrl = emotionInfo.image;
            item.createdAt = emotionInfo.created_at;
            item.updatedAt = emotionInfo.updated_at;
            item.imageId = emotionInfo.image_id;
        }
        this.loadingEmotions = false;
    },
    mounted() {},
    methods: {
        disableLocale(selectedLocale) {
            const locale = this.locales.find(locale => locale.id == selectedLocale);
            locale.disabled = true;
            return locale.name;
        },
        openPriceDialog() {
            if (this.status == 0) {
                this.priceSetting = {
                    level: 3,
                    candy: 10,
                    gem: 1
                }
            }
            this.priceDialog = true;
        },
        async approve() {
            /// Update review status
            this.buttonLoading = true;
            if (this.status == 0) {
                /// Approve role application and add to shop
                const data = {
                    status: 1
                }
                await reviewServices.sendRoleReviewResult(this.applicationId, data);
                /// Update shop item
                const shopItemData = {
                    role_id: this.roleId,
                    level: this.priceSetting.level,
                    candy: this.priceSetting.candy,
                    gem: this.priceSetting.gem
                }
                await reviewServices.addRoleToShop(shopItemData);
                this.buttonLoading = false;
                this.$router.go(-1);
            } else {
                /// Update item in shop
                const shopItemData = {
                    role_id: this.roleId,
                    level: this.priceSetting.level,
                    candy: this.priceSetting.candy,
                    gem: this.priceSetting.gem
                }
                await reviewServices.updateRoleShop(shopItemData);
                this.buttonLoading = false;
                this.priceDialog = false;
            }
        },
        openReviewDialog() {
            this.reviewMessage = '';
            this.reviewDialog = true;
        },
        async saveReview() {
            if (this.$refs.reviewForm.validate()) {
                this.buttonLoading = true;
                const data = {
                    status: -1,
                    comment: this.reviewMessage
                }
                await reviewServices.sendRoleReviewResult(this.applicationId, data);
                this.buttonLoading = false;
                this.$router.go(-1);
            }
        },
        previewEmotion(item) {
            this.previewItem = {
                imgId: item.imageId,
                emotionId: item.id,
                name: item.name,
                image: ''
            }
            this.fetchPreviewImage(item.imageId, item.id);
            this.previewEmotionDialog = true;
        },
        async fetchPreviewImage(imageId, emotionId) {
            const result = await roleServices.getPreviewRoleComposite(this.roleId,
                imageId, emotionId);
            this.previewItem.image = `data:image/png;base64,${result.img_base64_src}`;
        },
        async loadArtistInfo(artistId) {
            this.artistInfo = await reviewServices.getArtist(artistId);
            this.artistLoaded = true;
        },
        async loadThemeInfo(themeId) {
            this.themeInfo = await themeServices.getThemeInfo(themeId);
            this.themeLoaded = true;
        },
    },
};
</script>

<style scoped>
.role-thumbnail {
    width: 70px;
}

.commonColor {
    color: #424242;
}

.emo-img-block {
    width: 72;
}

.emo-img-block>div {
    aspect-ratio: 5 / 6;
}

.preview-image {
    width: 320px;
}

@media (min-width: 600px) {


    .emo-img-block {
        width: 120px;
    }
}

@media (min-width: 960px) {


    .emo-img-block {
        width: 132px;
    }
}

@media (min-width: 1264px) {


    .emo-img-block {
        width: 180;
    }
}
</style>