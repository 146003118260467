<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>
        <v-row no-gutters v-if="itemSelected">
            <v-col>
                <div>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            color="success" small
                            @click="approve"
                            :loading="buttonLoading">
                            通過
                        </v-btn>
                        <v-btn
                            color="error" small
                            @click="openReviewDialog()">
                            拒絕
                        </v-btn>
                    </v-toolbar>
                </div>
            </v-col>
        </v-row>
        <v-card outlined>
            <v-data-table
                :headers="headers"
                :items="backgrounds"
                :items-per-page="10"
                :loading="dataLoading">

                <template v-slot:item.thumbnail="{ item }" v-slot:activator>
                    <v-tooltip right color="grey darken-3">
                        <template v-slot:activator="{ on, attrs }">
                            <v-img :src="item.thumbnail" class="image-thumbnail mx-auto my-2"
                                v-bind="attrs" v-on="on">
                            </v-img>
                        </template>
                        <v-img class="grey darken-3" :src="item.thumbnail" style="width: 600px;">
                        </v-img>
                    </v-tooltip>
                </template>

                <template #item.status="{ item }">
                    <span :class="{ 'red--text': item.status === -1, 'green--text': item.status === 1 }">
                        {{ getStatusText(item.status) }}
                    </span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn small class="mr-2 mt-1" outlined @click="gotoReviewBackgroundDetail(item.application_id)">
                        <v-icon small class="mr-1"> mdi-eye </v-icon>
                        查看
                    </v-btn>
                </template>
                <template v-slot:item.checkbox="{ item }">
                    <div class="checkbox-container">
                        <v-checkbox v-if="item.status === 0" v-model="item.checked"></v-checkbox>
                    </div>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="reviewDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">拒絕背景圖片申請</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="reviewForm">
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>拒絕原因</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-text-field label="" v-model="reviewMessage"
                                        :rules="reviewMessageRules" dense hide-details="auto"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="reviewDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveReview" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="previewDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-text>
                    <v-img class="preview-image mx-auto" :src="previewImage">
                    </v-img>

                </v-card-text>

            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { reviewServices } from "@/services/review.js";
export default {
    name: "reviewBackgroundList",
    components: {
    },
    data() {
        return {
            breadcrumbsItems: [],
            infoMode: 'show',
            headers: [
                {
                    text: '縮圖',
                    sortable: false,
                    value: 'thumbnail',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '圖片寬高',
                    value: 'width_height',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '主題',
                    value: 'theme',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '申請日期',
                    value: 'apply_date',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '狀態',
                    value: 'status',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '功能', value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '批次審核', value: 'checkbox', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
            ],
            backgrounds: [],
            itemSelected: false,
            reviewDialog: false,
            reviewMessage: '',
            reviewMessageRules: [(v) => !!v || '請輸入審核意見'],
            buttonLoading: false,
            dataLoading: false,
            previewDialog: false,
            previewImage: '',
        };
    },
    async created() {
        this.breadcrumbsItems.push({
            text: '背景圖審核',
            disabled: true,
            href: ``,
        });
        this.dataLoading = true;
        this.fetchData();
    },
    mounted() {
    },
    watch: {
        backgrounds: {
            handler(backgrounds) {
                this.itemSelected = false;
                backgrounds.forEach(item => {
                    if (item.checked) {
                        this.itemSelected = true;
                    }
                });
            },
            deep: true
        }
    },
    methods: {
        async fetchData() {
            const response = await reviewServices.getReviewImageList('1');
            if (response.images) {
                let i = 0;
                for (let image of response.images) {
                    this.backgrounds.push({
                        id: i,
                        image_id: image.image_id,
                        application_id: image.application_id,
                        apply_date: image.apply_date,
                        thumbnail: image.image_url,
                        status: image.status,
                        width_height: `${image.width} x ${image.height}`,
                        theme: image.theme,
                        checked: false,
                    });
                    i++;
                }
            }
            this.dataLoading = false;
        },
        gotoReviewBackgroundDetail(id) {
            this.$router.push(`/console/review/backgrounds/${id}`);
        },
        getStatusText(statusCode) {
            switch (statusCode) {
                case 0:
                    return '待審核';
                case 1:
                    return '已上架';
                default:
                    return '已拒絕';
            }
        },
        async approve() {
            this.buttonLoading = true;
            const data = {
                status: 1
            }
            /// send review to all selected items
            for (let item of this.backgrounds) {
                if (item.checked) {
                    await reviewServices.sendReviewImageResult(item.application_id, data)
                }
            }
            this.backgrounds = [];
            await this.fetchData();
            this.buttonLoading = false;
        },
        openReviewDialog() {
            this.reviewDialog = true;
        },
        async saveReview() {
            if (this.$refs.reviewForm.validate()) {
                this.buttonLoading = true;
                const data = {
                    status: -1,
                    comment: this.reviewMessage
                }
                /// send review to all selected items
                for (let item of this.backgrounds) {
                    if (item.checked) {
                        await reviewServices.sendReviewImageResult(item.application_id, data)
                    }
                }
                this.backgrounds = [];
                await this.fetchData();
                this.buttonLoading = false;
                this.reviewDialog = false;
            }
        },
        openPreviewDialog(item) {
            if (!this.previewDialog) {
                this.previewDialog = true;
                this.previewImage = item.thumbnail;
            }
        },
        closePreviewDialog() {
            if (this.previewDialog) {
                this.previewDialog = false;
                this.previewImage = '';
            }
        }
    },
};
</script>

<style scoped>
.image-thumbnail {
    width: 70px;
}

.checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip-background {
    background-color: white;
}
</style>