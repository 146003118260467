import serviceUtils from '../utils/service';

export const artistServices = {
    async getInfo() {
        try {
            const url = `${serviceUtils.resourceUrl('artistInfo')}`;
            const response = await serviceUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to getInfo err: ${err}`);
            throw err
        }
    },
    async updateInfo(payload) {
        try {
            const url = `${serviceUtils.resourceUrl('artistUpdate')}`;
            const response = await serviceUtils.post(url, payload);
            return response.data;
        } catch (err) {
            console.log(`failed to updateInfo err: ${err}`);
            throw err
        }
    },
    async deleteInfo(locale) {
        try {
            const url = `${serviceUtils.resourceUrl('artistDeleteInfo')}`
                .replace(':locale', locale);
            const response = await serviceUtils.delete(url);
            return response.data;
        } catch (err) {
            console.log(`failed to updateInfo err: ${err}`);
            throw err
        }
    },
    async updateSocialMedia(payload) {
        try {
            const url = `${serviceUtils.resourceUrl('artistUpdateSocialMedia')}`;
            const response = await serviceUtils.post(url, payload);
            return response.data;
        } catch (err) {
            console.log(`failed to updateSocialMedia err: ${err}`);
            throw err
        }
    },
    async deleteSocialMedia(type) {
        try {
            const url = `${serviceUtils.resourceUrl('artistDeleteSocialMedia')}`
                .replace(':type', type);
            const response = await serviceUtils.delete(url);
            return response.data;
        } catch (err) {
            console.log(`failed to deleteSocialMedia err: ${err}`);
            throw err
        }
    },
    async updateThumbnail(payload) {
        const formData = new FormData();
        for (let key in payload) {
            formData.append(key, payload[key]);
        }
        try {
            const url = `${serviceUtils.resourceUrl('artistUpdateThumbnail')}`;
            const response = await serviceUtils.postFormData(url, formData);    
            return response.data;
        } catch (err) {
            console.log(`failed to updateThumbnail err: ${err}`);
            throw err
        }
    },
    async updateCover(payload) {
        const formData = new FormData();
        for (let key in payload) {
            formData.append(key, payload[key]);
        }
        try {
            const url = `${serviceUtils.resourceUrl('artistUpdateCover')}`;
            const response = await serviceUtils.postFormData(url, formData);    
            return response.data;
        } catch (err) {
            console.log(`failed to updateThumbnail err: ${err}`);
            throw err
        }
    }
}