<template>
    <v-container fluid>
        <h2><v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs></h2>

        <h3>基本資料</h3>
        <v-card outlined>
            <v-card flat class="border-bottom" v-for="input in infoInputs" :key="input.id">
                <v-speed-dial class="edit-option-fab" v-model="input.editOptionFab" top right direction="left"
                    transition="slide-y-reverse-transition" open-on-hover>
                    <template v-slot:activator>
                        <v-btn v-model="input.editOptionFab" small fab text depressed>
                            <v-icon v-if="input.editOptionFab">
                                mdi-close
                            </v-icon>
                            <v-icon v-else>
                                mdi-cog
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-btn fab dark x-small color="green" @click="openEditInfoDialog(input)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn fab dark x-small color="red" v-if="infoInputs.length > 1"
                        @click="removeInfoLocale(input.locale)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-speed-dial>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>語言</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        {{ input.localeName }}
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>姓名</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        {{ input.name }}
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>描述</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        {{ input.intro }}
                    </v-col>
                </v-row>
            </v-card>
            <v-card flat>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>新增語言</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        <v-select v-model="infoLocaleSelector" :items="infoLocales" item-text="name" item-value="id"
                            label="語言" outlined dense single-line hide-details="auto"></v-select>
                        <v-btn class="mt-1" rounded small color="primary" dark @click="addInfo()"
                            :disabled="infoLocales.length == infoInputs.length">
                            <v-icon small dark>mdi-plus</v-icon>新增
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <v-card flat class="border-bottom">
                <v-speed-dial class="edit-option-fab" v-model="thumbnail.editOptionFab" top right direction="left"
                    transition="slide-y-reverse-transition" open-on-hover>
                    <template v-slot:activator>
                        <v-btn v-model="thumbnail.editOptionFab" small fab text depressed>
                            <v-icon v-if="thumbnail.editOptionFab">
                                mdi-close
                            </v-icon>
                            <v-icon v-else>
                                mdi-cog
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-btn fab dark x-small color="green" @click="openEditThumbnailDialog">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-speed-dial>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>大頭照</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        <v-img class="thumbnail-block" :src="thumbnail.imgUrl" v-if="thumbnail.imgUrl"></v-img>
                    </v-col>
                </v-row>
            </v-card>
            <v-card flat class="border-bottom">
                <v-speed-dial class="edit-option-fab" v-model="cover.editOptionFab" top right direction="left"
                    transition="slide-y-reverse-transition" open-on-hover>
                    <template v-slot:activator>
                        <v-btn v-model="cover.editOptionFab" small fab text depressed>
                            <v-icon v-if="cover.editOptionFab">
                                mdi-close
                            </v-icon>
                            <v-icon v-else>
                                mdi-cog
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-btn fab dark x-small color="green" @click="openEditCoverDialog">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-speed-dial>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>頁面圖</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        <v-img class="cover-block" :src="cover.imgUrl" v-if="cover.imgUrl"></v-img>
                        <span v-if="!cover.imgUrl">尚未設定</span>
                    </v-col>
                </v-row>
            </v-card>
        </v-card>
        <h3 class="mt-2">社群平台</h3>
        <v-card outlined>
            <v-card flat class="border-bottom" v-for="input in socialMediaInputs" :key="input.id">
                <v-speed-dial class="edit-option-fab" v-model="input.editOptionFab" top right direction="left"
                    transition="slide-y-reverse-transition" open-on-hover>
                    <template v-slot:activator>
                        <v-btn v-model="input.editOptionFab" small fab text depressed>
                            <v-icon v-if="input.editOptionFab">
                                mdi-close
                            </v-icon>
                            <v-icon v-else>
                                mdi-cog
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-btn fab dark x-small color="green" @click="openEditSocialMediaDialog(input)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn fab dark x-small color="red" v-if="infoInputs.length > 1" @click="removeSocialMedia(input.type)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-speed-dial>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>社群平台</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        {{ input.typeName }}
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>網址</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        {{ input.url }}
                    </v-col>
                </v-row>
            </v-card>
            <v-card flat>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>新增社群平台</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        <v-select v-model="socialMediaSelector" :items="socialMedias" item-text="name" item-value="id"
                            label="社群平台" outlined dense single-line hide-details="auto"></v-select>
                        <v-btn class="mt-1" rounded small color="primary" dark @click="addSocialMedia()">
                            <v-icon small dark>mdi-plus</v-icon>新增
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-card>
        <v-dialog v-model="editInfoDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">編輯基本資料 - {{ editInfo.localeName }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="roleTitleForm" v-model="isEditInfoValid" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>姓名</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-text-field label="名稱" v-model="editInfo.name" :rules="nameRules" single-line dense
                                        hide-details="auto"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>描述</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-textarea label="描述" v-model="editInfo.intro" :rules="nameRules" outlined single-line
                                        dense
                                        hide-details="false"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editInfoDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveInfo" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editThumbnailDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">編輯大頭照</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="thumbnailForm" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>大頭照</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-img class="thumbnail-block" :src="thumbnail.imgUrl"
                                        v-if="thumbnail.imgUrl && !editedThumbnail.thumbnailFiles"></v-img>
                                    <div class="thumbnail-block" v-if="editedThumbnail.thumbnailFiles">
                                        <div ref="thumbnailBlock">
                                            <vueCropper ref="thumbnailCropper" :img="thumbnailOption.img"
                                                :mode="option.mode" :canScale="option.canScale" :output-size="option.size"
                                                :output-type="option.outputType" :info="false" :full="option.full"
                                                :fixed="option.fixed" :fixed-number="thumbnailOption.fixedNumber"
                                                :can-move="option.canMove" :can-move-box="option.canMoveBox"
                                                :fixed-box="option.fixedBox" :original="option.original"
                                                :auto-crop="option.autoCrop"
                                                :auto-crop-width="thumbnailOption.autoCropWidth"
                                                :auto-crop-height="thumbnailOption.autoCropHeight"
                                                :center-box="option.centerBox">
                                            </vueCropper>
                                        </div>
                                        <!--
                                <v-slider v-model="scale" :max="20" :min="0" step="1" hide-details class="align-center"
                                    @input="scaleImg()"></v-slider>
                                -->
                                    </div>
                                    <v-file-input v-model="editedThumbnail.thumbnailFiles" label="大頭照"
                                        accept="image/png, image/jpeg" prepend-icon="mdi-camera"
                                        @change="$uploadImage(editedThumbnail.thumbnailFiles, thumbnailOption)"></v-file-input>
                                </v-col>
                            </v-row>

                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editThumbnailDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveThumbnail" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editCoverDialog" max-width="800px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">編輯頁面圖</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="coverForm" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>頁面圖</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-img class="cover-block" :src="cover.imgUrl"
                                        v-if="cover.imgUrl && !editedCover.coverFiles"></v-img>
                                    <div class="cover-block" v-if="editedCover.coverFiles">
                                        <div ref="coverBlock">
                                            <vueCropper ref="coverCropper" :img="coverOption.img"
                                                :mode="option.mode" :canScale="option.canScale" :output-size="option.size"
                                                :output-type="option.outputType" :info="false" :full="option.full"
                                                :fixed="option.fixed" :fixed-number="coverOption.fixedNumber"
                                                :can-move="option.canMove" :can-move-box="option.canMoveBox"
                                                :fixed-box="option.fixedBox" :original="option.original"
                                                :auto-crop="option.autoCrop"
                                                :auto-crop-width="coverOption.autoCropWidth"
                                                :auto-crop-height="coverOption.autoCropHeight"
                                                :center-box="option.centerBox">
                                            </vueCropper>
                                        </div>
                                    </div>
                                    <v-file-input v-model="editedCover.coverFiles" label="頁面圖"
                                        accept="image/png, image/jpeg" prepend-icon="mdi-camera"
                                        @change="$uploadImage(editedCover.coverFiles, coverOption)"></v-file-input>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editCoverDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveCover" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editSocialMediaDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">編輯基本資料 - {{ editSocialMedia.typeName }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="socialMediaForm" v-model="isEditSocialMediaValid" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>網址</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-text-field label="網址" v-model="editSocialMedia.url" :rules="socialMediaUrlRules"
                                        single-line dense hide-details="auto"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editSocialMediaDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveSocialMedia" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { VueCropper } from 'vue-cropper';
import { artistServices } from "@/services/artist.js";

export default {
    name: "artistInfo",
    components: {
        VueCropper
    },
    data() {
        return {
            breadcrumbsItems: [],
            buttonLoading: false,
            infoCounter: 0,
            infoInputs: [],
            editInfoDialog: false,
            isEditInfoValid: true,
            editInfo: {
                locale: '',
                localeName: '',
                name: '',
                intro: ''
            },
            nameRules: [(v) => !!v || "必須填寫"],
            infoLocaleSelector: '',
            infoLocales: [{
                id: 'zh',
                name: '中文',
                disabled: false
            }, {
                id: 'en',
                name: '英文',
                disabled: false
            }],
            option: this.$cropperOption,
            editThumbnailDialog: false,
            thumbnail: {
                editOptionFab: false,
                imgUrl: '',
            },
            editedThumbnail: {
                imgUrl: '',
                thumbnailFiles: ''
            },
            thumbnailOption: {
                img: '',
                fixedNumber: [1, 1],
                autoCropHeight: 150,
                autoCropWidth: 150,
            },
            editCoverDialog: false,
            cover: {
                editOptionFab: false,
                imgUrl: '',
            },
            editedCover: {
                imgUrl: '',
                coverFiles: ''
            },
            coverOption: {
                img: '',
                fixedNumber: [32, 15],
                autoCropHeight: 420,
                autoCropWidth: 1280,
            },
            socialMediaCounter: 0,
            socialMediaSelector: '',
            socialMedias: [{
                id: 'fb',
                name: 'Facebook'
            }, {
                id: 'ig',
                name: 'Instagram'
            }, {
                id: 'web',
                name: '網站'
            }],
            socialMediaInputs: [],
            editSocialMediaDialog: false,
            isEditSocialMediaValid: true,
            editSocialMedia: {
                type: '',
                typeName: '',
                url: ''
            },
            //socialMediaUrlRules: [(v) => !!v || "必須填寫"],
            socialMediaUrlRules: [
                (v) => !!v || "欄位未填",
                (v) =>
                    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/.test(
                        v
                    ) || "格式錯誤",
            ],
        };
    },
    async created() {
        this.breadcrumbsItems.push({
            text: '作者介紹',
            disabled: true,
            href: ``,
        });
        const consoleIndexUrl = this.$store.getters.consoleIndexUrl

        if (consoleIndexUrl != null) {
            this.$router.push(consoleIndexUrl);
        }

        const info = await artistServices.getInfo();
        const infoList = info.basic_info_list;
        // const infoList = [{
        //     id: '1.zh',
        //     locale: 'zh',
        //     name: '小馬 倪子鈞',
        //     intro: '小馬為台灣多元發展的藝人，從歌手、戲劇到主持皆有其代表作。沒有藝術基底的他，之所以投入藝術創作，是源於 2015 年所作的一場異夢，夢境內的場景和他所聽到的聲音，都要他用藝術來幫助人，後在一次偶然的機會，他接觸藝術創作竟感受前所未有的喜悅，自此其生命過去的經歷跟信仰帶給他生命的改變，成為他藝術創作的靈感泉源及能量。 小馬的繪畫完全是自發性創作，沒有專業技術的包袱，其天馬行空不按牌理出牌的創作過程，就像魚在水裡一樣悠游自在，他從簡易的魚型線條加上繽紛的色塊鋪成，逐漸發展出不同的造型變化，最終誕生「療魚貓」這個療癒的角色，小馬期許透過藝術，療癒自己也帶給他人療癒。'
        // }];
        /*, {
                    id: '1.en',
                    locale: 'en',
                    name: 'Morrison Ni',
                    intro: 'Morrison Ni (小馬), formally known as Ni Zijun (倪子鈞), is a Taiwanese artist with diverse talents; singer, actor, host – he is involved in multiple fields. Despite not having a background in art, he has devoted himself to artistic creation, all because of a dream he had in 2015. The sights and sounds he experienced in his dream impelled him to use art to help people. One day, after an eninfoCounter with art, he felt unprecedented joy. Since then, his life experiences and the changes brought about by his beliefs have become the source of inspiration and motivation for his artistic creations. Though he did not receive a professional education, Morrison Ni draws his creations from his heart. His creativity is unrestrained, like a fish swimming freely in water. With that as an idea, he started by creating simple fish-shaped patterns with different colors and linking the fish together to form more complicated shapes. This eventually led to the creation of "JOYFISHCAT" (療魚貓). Morrison Ni hopes his art can heal others; just as how it has healed him.'
                } */
        let id = 0;
        infoList.forEach(info => {
            let localeName = this.disableInfoLocale(info.locale)
            this.infoInputs.push({
                editOptionFab: false,
                id: id,
                locale: info.locale,
                localeName: localeName,
                name: info.name,
                intro: info.intro,
            });
            id += 1;
        });
        this.thumbnail.imgUrl = info.thumbnail;
        // this.thumbnail.imgUrl = 'https://s3.ap-northeast-1.amazonaws.com/diary.mangachat/shop/artist/1/avatar.jpg';
        this.cover.imgUrl = info.cover;
        const socialMediaList = info.social_media_list;
        id = 0;
        socialMediaList.forEach(socialMedia => {
            let typeName = this.disableSocialMedia(socialMedia.type);
            this.socialMediaInputs.push({
                id: id,
                type: socialMedia.type,
                typeName: typeName,
                url: socialMedia.url,
            });
            id += 1;
        });
    },
    mounted() {

    },
    methods: {
        disableInfoLocale(selectedLocale) {
            const locale = this.infoLocales.find(locale => locale.id == selectedLocale);
            locale.disabled = true;
            return locale.name;
        },
        enableInfoLocale(selectedLocale) {
            const locale = this.infoLocales.find(locale => locale.id == selectedLocale);
            locale.disabled = false;
        },
        addInfo() {
            let selectedLocale = this.infoLocaleSelector;
            if (selectedLocale == '') {
                return;
            }
            const locale = this.infoLocales.find(locale => locale.id == selectedLocale);

            this.editInfo = {
                locale: selectedLocale,
                localeName: locale.name,
                name: '',
                intro: ''
            }
            this.editInfoDialog = true;
            this.infoLocaleSelector = '';
        },
        openEditInfoDialog(item) {
            this.editInfo = {
                id: item.id,
                name: item.name,
                intro: item.intro,
                localeName: item.localeName,
                locale: item.locale
            }
            this.editInfoDialog = true;
        },
        async saveInfo() {
            if (this.$refs.roleTitleForm.validate()) {
                if (this.editInfo.id != null) {
                    let info = this.infoInputs.find(info => info.id == this.editInfo.id);
                    info.name = this.editInfo.name;
                    info.intro = this.editInfo.intro;
                } else {
                    this.infoInputs.push({
                        editOptionFab: false,
                        id: 1,
                        name: this.editInfo.name,
                        intro: this.editInfo.intro,
                        localeName: this.editInfo.localeName,
                        locale: this.editInfo.locale
                    });
                }
                this.disableInfoLocale(this.editInfo.locale);
                await artistServices.updateInfo({
                    locale: this.editInfo.locale,
                    name: this.editInfo.name,
                    intro: this.editInfo.intro
                });
                this.buttonLoading = false;
                this.editInfoDialog = false;
            }
        },
        windowResize() {
            this.$cropperResize(this.$refs.thumbnailBlock, this.$refs.thumbnailCropper, this.thumbnailOption);
            this.$cropperResize(this.$refs.coverBlock, this.$refs.coverCropper, this.coverOption);
        },
        openEditThumbnailDialog() {
            this.editedThumbnail.thumbnailFiles = null;
            this.editThumbnailDialog = true;
        },
        saveThumbnail() {
            //Get the cropped image data and use artistServices to update the thumbnail
            if (this.$refs.thumbnailForm.validate()) {
                // Promise.all([this.$getCropperImg(this.$refs.thumbnailCropper)])
                this.buttonLoading = true;
                Promise.all([this.$getCropperData(this.$refs.thumbnailCropper)])
                    .then(async result => {
                        let postData = {};
                        if (result[0].status == 1) {
                            postData['thumbnail_img'] = result[0].data;
                            const updateResult = await artistServices.updateThumbnail(postData);
                            const randomNumber = ((Math.random() * 46656) | 0);
                            this.thumbnail.imgUrl = `${updateResult.thumbnail_url}?${randomNumber}`;
                            this.buttonLoading = false;
                            this.editThumbnailDialog = false;
                        }
                    });
            }
            // this.editThumbnailDialog = false;
        },
        openEditCoverDialog() {
            this.editedCover.coverFiles = null;
            this.editCoverDialog = true;
        },
        saveCover() {
            if (this.$refs.coverForm.validate()) {
                this.buttonLoading = true;
                // Promise.all([this.$getCropperImg(this.$refs.coverCropper)])
                Promise.all([this.$getCropperData(this.$refs.coverCropper)])
                    .then(async result => {
                        let postData = {};
                        if (result[0].status == 1) {
                            postData['cover_img'] = result[0].data;
                            const updateResult = await artistServices.updateCover(postData);
                            const randomNumber = ((Math.random() * 46656) | 0);
                            this.cover.imgUrl = `${updateResult.cover_url}?${randomNumber}`;
                            this.editCoverDialog = false;
                            this.buttonLoading = false;
                        }
                    });
            }
        },
        async removeInfoLocale(selectedLocale) {
            this.enableInfoLocale(selectedLocale);
            for (let i = 0; i < this.infoInputs.length; i++) {
                let infoInput = this.infoInputs[i];
                if (infoInput.locale == selectedLocale) {
                    this.infoInputs.splice(i, 1);
                    await artistServices.deleteInfo(selectedLocale);
                }
            }
        },
        disableSocialMedia(selectedSocialMedia) {
            const socialMedia = this.socialMedias.find(socialMedia => socialMedia.id == selectedSocialMedia);
            // if (selectedSocialMedia != 'web')
            socialMedia.disabled = true;

            return socialMedia.name;
        },
        enableSocialMedia(selectedSocialMedia) {
            const socialMedia = this.socialMedias.find(socialMedia => socialMedia.id == selectedSocialMedia);
            socialMedia.disabled = false;
        },
        addSocialMedia() {
            let selectedSocialMedia = this.socialMediaSelector;
            if (selectedSocialMedia == '') {
                return;
            }
            const socialMedia = this.socialMedias.find(socialMedia => socialMedia.id == selectedSocialMedia);

            //let selectedSocialMediaName = this.disableSocialMedia(selectedSocialMedia);
            this.editSocialMedia = {
                type: selectedSocialMedia,
                typeName: socialMedia.name,
                url: ''
            };
            this.socialMediaSelector = '';
            this.editSocialMediaDialog = true;
        },
        openEditSocialMediaDialog(item) {
            this.editSocialMedia = {
                id: item.id,
                type: item.type,
                typeName: item.typeName,
                url: item.url
            };
            this.editSocialMediaDialog = true;
        },
        async removeSocialMedia(selectedSocialMedia) {
            this.enableSocialMedia(selectedSocialMedia);
            for (let i = 0; i < this.socialMediaInputs.length; i++) {
                let socialMediaInput = this.socialMediaInputs[i];
                if (socialMediaInput.type == selectedSocialMedia) {
                    this.socialMediaInputs.splice(i, 1);
                    await artistServices.deleteSocialMedia(selectedSocialMedia);
                }
            }
        },
        async saveSocialMedia() {
            if (this.$refs.socialMediaForm.validate()) {
                this.buttonLoading = true;
                if (this.editSocialMedia.id != null) {
                    let socialMedia = this.socialMediaInputs.find(socialMedia => socialMedia.id == this.editSocialMedia.id);
                    socialMedia.url = this.editSocialMedia.url;
                } else {
                    this.socialMediaInputs.push({
                        editOptionFab: false,
                        id: this.socialMediaInputs.length,
                        type: this.editSocialMedia.type,
                        typeName: this.editSocialMedia.typeName,
                        url: this.editSocialMedia.url
                    });
                }

                this.disableSocialMedia(this.editSocialMedia.type);
                this.buttonLoading = false;
                this.editSocialMediaDialog = false;

                await artistServices.updateSocialMedia({
                    type: this.editSocialMedia.type,
                    url: this.editSocialMedia.url
                });

            } else {
                console.log('saveSocialMedia validate false')
            }
        }
    },
};
</script>

<style>
.cover-block {
    width: 240px;
}

.cover-block>div {
    aspect-ratio: 32 / 15;
}

@media (min-width: 600px) {
    .cover-block {
        width: 400px;
    }
}

@media (min-width: 960px) {
    .cover-block {
        width: 440px;
    }
}

@media (min-width: 1264px) {
    .cover-block {
        width: 440px !important;
    }
}
</style>