import serviceUtils from '../utils/service'

export const roleServices = {
    async getInfo(id) {
        try {
            const url = `${serviceUtils.resourceUrl('roleInfo')}`
                .replace(':id', id);
            const response = await serviceUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to getList err: ${err}`);
            throw err
        }
    },
    async getEmotion(roleId, emotionId) {
        try {
            const url = `${serviceUtils.resourceUrl('roleEmotion')}`
                .replace(':id', roleId)
                .replace(':emotionId', emotionId);
            const response = await serviceUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to getList err: ${err}`);
            throw err
        }
    },
    async updateThumbnail(roleId, payload) {
        const formData = new FormData();
        for (let key in payload) {
            formData.append(key, payload[key]);
        }
        try {
            const url = `${serviceUtils.resourceUrl('roleThumbnail')}`
                .replace(':id', roleId);
            const response = await serviceUtils.postFormData(url, formData);
            return response.data;
        } catch (err) {
            console.log(`failed to updateThumbnail err: ${err}`);
            throw err
        }
    },
    async updateFace(roleId, payload) {
        const formData = new FormData();
        for (let key in payload) {
            formData.append(key, payload[key]);
        }
        try {
            const url = `${serviceUtils.resourceUrl('roleFace')}`
                .replace(':id', roleId);
            const response = await serviceUtils.postFormData(url, formData);
            return response.data;
        } catch (err) {
            console.log(`failed to updateFace err: ${err}`);
            throw err
        }
    },
    async updateEmotionFile(payload) {
        const formData = new FormData();
        for (let key in payload) {
            formData.append(key, payload[key]);
        }
        try {
            const url = `${serviceUtils.resourceUrl('roleEmotionFile')}`;
            const response = await serviceUtils.postFormData(url, formData);
            return response.data;
        } catch (err) {
            console.log(`failed to updateEmotion err: ${err}`);
            throw err
        }
    },
    async updateRoleInfoLocale(id, locale, payload) {
        try {
            const url = `${serviceUtils.resourceUrl('roleInfoLocale')}`
                .replace(':id', id)
                .replace(':locale', locale);
            const response = await serviceUtils.post(url, payload);
            return response.data;
        } catch (err) {
            console.log(`failed to updateRoleInfoLocale err: ${err}`);
            throw err
        }
    },
    async updateRoleInfo(id, payload) {
        try {
            const url = `${serviceUtils.resourceUrl('roleInfoUpdate')}`
                .replace(':id', id);
            const response = await serviceUtils.post(url, payload);
            return response.data;
        } catch (err) {
            console.log(`failed to updateRoleInfo err: ${err}`);
            throw err
        }
    },
    async addRole(payload) {
        try {
            const url = `${serviceUtils.resourceUrl('addRole')}`;
            const response = await serviceUtils.post(url, payload);
            return response.data;
        } catch (err) {
            console.log(`failed to updateRoleInfo err: ${err}`);
            throw err
        }
    },
    async getRoleStatus(id) {
        try {
            const url = `${serviceUtils.resourceUrl('roleStatus')}`
                .replace(':id', id);
            const response = await serviceUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to updateRoleInfo err: ${err}`);
            throw err
        }
    },
    async apply(id) {
        try {
            const url = `${serviceUtils.resourceUrl('roleApply')}`
                .replace(':id', id);
            const response = await serviceUtils.post(url);
            console.log('response', response);
            return response.data;
        } catch (err) {
            console.log(`failed to apply role err: ${err}`);
            throw err
        }
    },
    async revoke(id) {
        try {
            const url = `${serviceUtils.resourceUrl('roleRevoke')}`
                .replace(':id', id);
            const response = await serviceUtils.post(url);
            console.log('response', response);
            return response.data;
        } catch (err) {
            console.log(`failed to revoke role err: ${err}`);
            throw err
        }
    },
    async revokeApply(id) {
        try {
            const url = `${serviceUtils.resourceUrl('roleApplyRevoke')}`
                .replace(':id', id);
            const response = await serviceUtils.delete(url);
            console.log('response', response);
            return response.data;
        } catch (err) {
            console.log(`failed to revoke role err: ${err}`);
            throw err
        }
    },
    async getPreviewRoleComposite(roleId, imageId, emotionId) {
        try {
            // const url = `${serviceUtils.diaryAPIUrl('compose')}`
            const url = `${serviceUtils.resourceUrl('rolePreviewImage')}`
            const payload = {
                "message_type": "st",
                "role_id": roleId,
                "emotion_ids": emotionId,
                "message": "畫格預覽",
                "is_transparent_bg": false,
                "isComposeRole": true,
                "img_id": imageId
            };
            const response = await serviceUtils.post(url, payload);
            return response.data;
        } catch (err) {
            console.log(`failed to getPreviewRoleComposite err: ${err}`);
            throw err
        }
    }
}