<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>
        <v-tabs v-model="tab">
            <v-tabs-slider color="deep-orange arken-1"></v-tabs-slider>

            <v-tab href="#tabInfo" class="grey--text text--darken-2 subtitle-1">
                基本資料
            </v-tab>
            <v-tab href="#tabRole" class="grey--text text--darken-2 subtitle-1">
                角色列表
            </v-tab>
            <v-tab href="#tabBackground" class="grey--text text--darken-2 subtitle-1">
                背景列表
            </v-tab>
            <v-tab href="#tabDialog" class="grey--text text--darken-2 subtitle-1">
                對話框列表
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="mt-2">
            <v-tab-item value="tabInfo">

                <v-card outlined>
                    <v-card flat class="border-bottom" v-for="input in basicInfos" :key="input.id">
                        <v-speed-dial class="edit-option-fab" v-model="input.editOptionFab" top right direction="left"
                            transition="slide-y-reverse-transition" open-on-hover>
                            <template v-slot:activator>
                                <v-btn v-model="input.editOptionFab" small fab text depressed>
                                    <v-icon v-if="input.editOptionFab">
                                        mdi-close
                                    </v-icon>
                                    <v-icon v-else>
                                        mdi-cog
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-btn fab dark x-small color="green" @click="openEditThemeDialog(input)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn fab dark x-small color="red" v-if="basicInfos.length > 1" @click="deleteLocale(input)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-speed-dial>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>語言</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                {{ input.localeName }}

                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>名稱</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                {{ input.name }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>Logo</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                <v-img class="logo-block" :src="input.logoUrl" v-if="input.logoUrl"></v-img>
                                <span v-if="!input.logoUrl">無設定</span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>主題大圖</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                <v-img class="banner-block" :src="input.bannerUrl" v-if="input.bannerUrl"></v-img>
                                <span v-if="!input.bannerUrl">無設定</span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>主題縮圖</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                <v-img class="avatar-block" :src="input.avatarUrl" v-if="input.avatarUrl"></v-img>
                                <span v-if="!input.avatarUrl">無設定</span>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat>
                        <v-row no-gutters>
                            <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                                <v-subheader>新增語言</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                                <v-select v-model="localeSelector" :items="locales" item-text="name" item-value="id"
                                    label="語言" outlined dense single-line hide-details="auto"></v-select>
                                <v-btn class="mt-1" rounded small color="primary" dark @click="addLocale()">
                                    <v-icon small dark>mdi-plus</v-icon>新增
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tabRole">
                <v-card outlined class="py-3 px-5">
                    <v-data-table :headers="headers" :items="roles" hide-default-footer disable-sort>
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-3" outlined small @click="newRole">
                                    <v-icon small>mdi-plus</v-icon> 新增角色
                                </v-btn>
                            </v-toolbar>
                        </template>
                        <template #item.status="{ item }">
                            <span>{{ getStatusText(item.status_code) }}</span>
                        </template>
                        <template v-slot:item.thumbnail="{ item }">
                            <v-img v-if="item.thumbnail != null" :src="item.thumbnail"
                                class="role-thumbnail  mx-auto mt-2 mb-2">
                            </v-img>
                            <span v-if="!item.thumbnail">尚未設定</span>

                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn small class="mr-2 mt-1" outlined @click="goRoleDetail(item.id)">
                                <v-icon small class="mr-1"> mdi-eye </v-icon>
                                查看
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tabBackground">
                <v-card outlined class="py-3 px-5">
                    <v-data-table :headers="backgroundHeaders" :items="backgrounds" hide-default-footer disable-sort>
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-3" outlined small @click="openEditBackgroundDialog(null)">
                                    <v-icon small>mdi-plus</v-icon> 新增背景
                                </v-btn>
                                <v-btn v-if="applyBackgroundButtonShow"
                                    class="ml-2"
                                    color="blue darken-3" outlined small
                                    @click="applyBackground()"
                                    :loading="buttonLoading"
                                    :disabled="applyBackgroundButtonDisabled">
                                    {{ applyBackgroundButtonDisabled ? '申請審核中' : '送出審核申請' }}
                                </v-btn>
                            </v-toolbar>
                        </template>

                        <template #item.status="{ item }">
                            <span>{{ getStatusText(item.status_code) }}</span>
                        </template>
                        <template v-slot:item.thumbnail="{ item }">
                            <v-img v-if="item.thumbnail != null" :src="item.thumbnail"
                                class="role-thumbnail  mx-auto mt-2 mb-2">
                            </v-img>
                            <span v-if="!item.thumbnail">尚未設定</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn small class="mr-2 mt-1" outlined @click="openDeleteImageDialog(item, typeBackground)">
                                <v-icon small class="mr-1"> mdi-eye </v-icon>
                                刪除
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tabDialog">
                <v-card outlined class="py-3 px-5">
                    <v-data-table :headers="bubbleHeaders" :items="dialogs" hide-default-footer disable-sort>
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-3" outlined small @click="openEditBubbleDialog">
                                    <v-icon small>mdi-plus</v-icon> 新增對話框
                                </v-btn>
                                <v-btn v-if="applyBubbleButtonShow"
                                    class="ml-2"
                                    color="blue darken-3" outlined small
                                    @click="applyBubble()"
                                    :loading="buttonLoading"
                                    :disabled="applyBubbleButtonDisabled">
                                    {{ applyBubbleButtonDisabled ? '申請審核中' : '送出審核申請' }}
                                </v-btn>
                            </v-toolbar>
                        </template>

                        <template #item.status="{ item }">
                            <span>{{ getStatusText(item.status_code) }}</span>
                        </template>
                        <template v-slot:item.thumbnail="{ item }">
                            <v-img v-if="item.thumbnail != null" :src="item.thumbnail"
                                class="role-thumbnail  mx-auto mt-2 mb-2">
                            </v-img>
                            <span v-if="!item.thumbnail">尚未設定</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn small class="mr-2 mt-1" outlined @click="openDeleteImageDialog(item, typeDialog)">
                                <v-icon small class="mr-1"> mdi-eye </v-icon>
                                刪除
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog v-model="editThemeDialog" max-width="800px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">編輯資料 - {{ editStyleInfo.localeName }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="paintStyleForm" v-model="isEditPaintStyleValid" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>名稱</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-text-field label="名稱" v-model="editStyleInfo.name" single-line dense
                                        hide-details="auto"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>Logo</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-img class="logo-block" :src="editStyleInfo.logoUrl"
                                        v-if="editStyleInfo.logoUrl && !editStyleInfo.logoFiles"></v-img>
                                    <div class="logo-block" v-if="editStyleInfo.logoFiles">
                                        <div ref="logoBlock">
                                            <vueCropper ref="logoCropper" :img="logoOption.img" :mode="option.mode"
                                                :canScale="option.canScale" :output-size="option.size"
                                                :output-type="option.outputType" :info="false" :full="option.full"
                                                :fixed="option.fixed" :fixed-number="logoOption.fixedNumber"
                                                :can-move="option.canMove" :can-move-box="option.canMoveBox"
                                                :fixed-box="option.fixedBox" :original="option.original"
                                                :auto-crop="option.autoCrop" :auto-crop-width="logoOption.autoCropWidth"
                                                :auto-crop-height="logoOption.autoCropHeight"
                                                :center-box="option.centerBox">
                                            </vueCropper>
                                        </div>
                                        <!--
                                <v-slider v-model="scale" :max="20" :min="0" step="1" hide-details class="align-center"
                                    @input="scaleImg()"></v-slider>
                                -->
                                    </div>
                                    <v-file-input v-model="editStyleInfo.logoFiles" label="Logo"
                                        accept="image/png, image/jpeg" :rules="logoFileRules" prepend-icon="mdi-camera"
                                        @change="$uploadImage(editStyleInfo.logoFiles, logoOption)"></v-file-input>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>主題大圖</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-img class="banner-block" :src="editStyleInfo.bannerUrl"
                                        v-if="editStyleInfo.bannerUrl && !editStyleInfo.bannerFiles"></v-img>
                                    <div class="banner-block" v-if="editStyleInfo.bannerFiles">
                                        <div ref="bannerBlock">
                                            <vueCropper ref="bannerCropper" :img="bannerOption.img" :mode="option.mode"
                                                :canScale="option.canScale" :output-size="option.size"
                                                :output-type="option.outputType" :info="false" :full="option.full"
                                                :fixed="option.fixed" :fixed-number="bannerOption.fixedNumber"
                                                :can-move="option.canMove" :can-move-box="option.canMoveBox"
                                                :fixed-box="option.fixedBox" :original="option.original"
                                                :auto-crop="option.autoCrop" :auto-crop-width="bannerOption.autoCropWidth"
                                                :auto-crop-height="bannerOption.autoCropHeight"
                                                :center-box="option.centerBox">
                                            </vueCropper>
                                        </div>
                                    </div>
                                    <v-file-input v-model="editStyleInfo.bannerFiles" label="主題大圖"
                                        accept="image/png, image/jpeg" :rules="logoFileRules" prepend-icon="mdi-camera"
                                        @change="$uploadImage(editStyleInfo.bannerFiles, bannerOption)"></v-file-input>

                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>主題縮圖</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-img class="avatar-block" :src="editStyleInfo.avatarUrl"
                                        v-if="editStyleInfo.avatarUrl && !editStyleInfo.avatarFiles"></v-img>
                                    <div class="avatar-block" v-if="editStyleInfo.avatarFiles">
                                        <div ref="avatarBlock">
                                            <vueCropper ref="avatarCropper" :img="avatarOption.img" :mode="option.mode"
                                                :canScale="option.canScale" :output-size="option.size"
                                                :output-type="option.outputType" :info="false" :full="option.full"
                                                :fixed="option.fixed" :fixed-number="avatarOption.fixedNumber"
                                                :can-move="option.canMove" :can-move-box="option.canMoveBox"
                                                :fixed-box="option.fixedBox" :original="option.original"
                                                :auto-crop="option.autoCrop" :auto-crop-width="avatarOption.autoCropWidth"
                                                :auto-crop-height="avatarOption.autoCropHeight"
                                                :center-box="option.centerBox">
                                            </vueCropper>
                                        </div>
                                    </div>
                                    <v-file-input v-model="editStyleInfo.avatarFiles" label="主題縮圖"
                                        accept="image/png, image/jpeg" :rules="logoFileRules" prepend-icon="mdi-camera"
                                        @change="$uploadImage(editStyleInfo.avatarFiles, avatarOption)"></v-file-input>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editThemeDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveInfo" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="addRoleDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">新增角色</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="roleForm" v-model="isNewRoleValid" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="4" class="grey lighten-4">
                                    <v-subheader>語言</v-subheader>
                                </v-col>
                                <v-col cols="8" class="pa-2">
                                    中文
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" class="grey lighten-4">
                                    <v-subheader>角色</v-subheader>
                                </v-col>
                                <v-col cols="8" class="pa-2">
                                    <v-text-field label="名稱" v-model="roleName" :rules="roleNameRules" single-line dense
                                        hide-details="auto"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="addRoleDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveRole" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editBackgroundDialog" max-width="800px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">{{ editedBackground.isNew ? '新增背景圖' : '編輯背景圖' }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="backgroundForm" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>背景圖</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-img class="background-block" :src="background.imgUrl"
                                        v-if="background.imgUrl && !editedBackground.backgroundFiles"></v-img>
                                    <div class="background-block" v-if="editedBackground.backgroundFiles">
                                        <div ref="backgroundBlock">
                                            <vueCropper ref="backgroundCropper" :img="backgroundOption.img"
                                                :mode="option.mode" :canScale="option.canScale" :output-size="option.size"
                                                :output-type="option.outputType" :info="false" :full="option.full"
                                                :fixed="option.fixed" :fixed-number="backgroundOption.fixedNumber"
                                                :can-move="option.canMove" :can-move-box="option.canMoveBox"
                                                :fixed-box="option.fixedBox" :original="option.original"
                                                :auto-crop="option.autoCrop"
                                                :auto-crop-width="backgroundOption.autoCropWidth"
                                                :auto-crop-height="backgroundOption.autoCropHeight"
                                                :center-box="option.centerBox">
                                            </vueCropper>
                                        </div>
                                    </div>
                                    <v-file-input v-model="editedBackground.backgroundFiles" label="背景圖"
                                        accept="image/png, image/jpeg" :rules="backgroundFileRules"
                                        prepend-icon="mdi-camera"
                                        @change="$uploadImage(editedBackground.backgroundFiles, backgroundOption)"></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>標籤</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-text-field>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editBackgroundDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveBackground" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editBubbleDialog" max-width="800px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">新增對話框</span>
                </v-card-title>
                <v-card-title>
                    <span class="text-h6">對話框類型</span>
                </v-card-title>
                <v-radio-group
                    row
                    v-model="selectedBubbleType"
                    @change="bubbleTypeSelectionChange">
                    <v-col>
                        <v-radio
                            class="ml-2"
                            label="長方形(1200 x 900)"
                            value="rectangle">
                        </v-radio>
                        <v-img
                            class="bubble-example-block ma-4"
                            src=https://mangadev.s3.ap-northeast-1.amazonaws.com/official/bubble/small/5x8_1.png></v-img>
                    </v-col>
                    <v-col>
                        <v-radio
                            label="正方形(1200 x 1200)"
                            value="square"></v-radio>
                        <v-img
                            class="bubble-example-block ma-2"
                            src=https://mangadev.s3.ap-northeast-1.amazonaws.com/official/bubble/small/5x5_1.png></v-img>
                    </v-col>

                </v-radio-group>

                <v-card-text>
                    <v-container>
                        <v-form ref="bubbleForm" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>對話框</v-subheader>
                                </v-col>
                                <v-col v-if="bubbleTypeSquare" cols="8" sm="9" md="9" class="pa-2">
                                    <v-img class="bubble-block" :src="bubble.imgUrl"
                                        v-if="bubble.imgUrl && !editedBubble.bubbleFiles"></v-img>
                                    <div class="bubble-block" v-if="editedBubble.bubbleFiles">
                                        <div ref="bubbleBlock">
                                            <vueCropper ref="bubbleCropper" :img="bubbleOption.img"
                                                :mode="option.mode" :canScale="option.canScale" :output-size="option.size"
                                                :output-type="option.outputType" :info="false" :full="option.full"
                                                :fixed="option.fixed" :fixed-number="bubbleOption.fixedNumber"
                                                :can-move="option.canMove" :can-move-box="option.canMoveBox"
                                                :fixed-box="option.fixedBox" :original="option.original"
                                                :auto-crop="option.autoCrop"
                                                :auto-crop-width="bubbleOption.autoCropWidth"
                                                :auto-crop-height="bubbleOption.autoCropHeight"
                                                :center-box="option.centerBox">
                                            </vueCropper>
                                        </div>
                                    </div>
                                    <v-file-input v-model="editedBubble.bubbleFiles" label="對話框"
                                        accept="image/png, image/jpeg" :rules="bubbleFileRules"
                                        prepend-icon="mdi-camera"
                                        @change="$uploadImage(editedBubble.bubbleFiles, bubbleOption)"></v-file-input>
                                </v-col>
                                <v-col v-if="!bubbleTypeSquare" cols="8" sm="9" md="9" class="pa-2">
                                    <v-img class="bubble-rectangle-block" :src="bubble.imgUrl"
                                        v-if="bubble.imgUrl && !editedBubble.bubbleFiles"></v-img>
                                    <div class="bubble-rectangle-block" v-if="editedBubble.bubbleFiles">
                                        <div ref="bubbleBlock">
                                            <vueCropper ref="bubbleCropper" :img="bubbleOption.img"
                                                :mode="option.mode" :canScale="option.canScale" :output-size="option.size"
                                                :output-type="option.outputType" :info="false" :full="option.full"
                                                :fixed="option.fixed" :fixed-number="bubbleOption.fixedNumber"
                                                :can-move="option.canMove" :can-move-box="option.canMoveBox"
                                                :fixed-box="option.fixedBox" :original="option.original"
                                                :auto-crop="option.autoCrop"
                                                :auto-crop-width="bubbleOption.autoCropWidth"
                                                :auto-crop-height="bubbleOption.autoCropHeight"
                                                :center-box="option.centerBox">
                                            </vueCropper>
                                        </div>
                                    </div>
                                    <v-file-input v-model="editedBubble.bubbleFiles" label="對話框"
                                        accept="image/png, image/jpeg" :rules="bubbleFileRules"
                                        prepend-icon="mdi-camera"
                                        @change="$uploadImage(editedBubble.bubbleFiles, bubbleOption)"></v-file-input>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editBubbleDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveBubble" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="deleteImageDialog"
            persistent
            max-width="600px">
            <v-card>
                <v-card-title class="text-h5">
                    確定要刪除此張圖片嗎？
                </v-card-title>
                <v-card-text>執行圖片刪除後無法再復原，確定要刪除嗎？</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        :disabled="buttonLoading"
                        @click="deleteImageDialog = false">
                        取消
                    </v-btn>
                    <v-btn
                        color="light-green darken-2 white--text"
                        :loading="buttonLoading"
                        :disabled="buttonLoading"
                        @click="deleteImage()">
                        確定
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { VueCropper } from 'vue-cropper'
import { themeServices } from '@/services/theme.js';
import { roleServices } from '@/services/role.js';
import { imageServices } from '@/services/image.js';

export default {
    name: "paintStyleInfo",
    components: {
        VueCropper
    },
    data() {
        return {
            fab: false,
            paintStyleId: '',
            breadcrumbsItems: [],
            buttonLoading: false,
            tab: null,
            infoMode: 'show',
            localeSelector: '',
            locales: [{
                id: 'zh',
                name: '中文',
                disabled: false
            }, {
                id: 'en',
                name: '英文',
                disabled: false
            }],
            editThemeDialog: false,
            isEditPaintStyleValid: true,
            basicInfos: [],
            editStyleInfo: {
                name: '',
                logoUrl: '',
                bannerUrl: '',
                avatarUrl: '',
                logoFiles: null,
                bannerFiles: null,
                avatarFiles: null
            },
            logoFileRules: [(v) => (!!v || !!this.editStyleInfo.logoUrl) || "必須上傳圖片"],
            scale: 0,
            lastScale: 0,
            option: this.$cropperOption,
            logoOption: {
                img: '',
                fixedNumber: [4, 1],
                autoCropHeight: 150,
                autoCropWidth: 600,
                autoCrop: true
            },
            bannerOption: {
                img: '',
                fixedNumber: [4, 1],
                autoCropHeight: 150,
                autoCropWidth: 600,
                autoCrop: true
            },
            avatarOption: {
                img: '',
                fixedNumber: [1, 1],
                autoCropHeight: 350,
                autoCropWidth: 350,
                autoCrop: true
            },
            headers: [
                {
                    text: '縮圖',
                    sortable: false,
                    value: 'thumbnail',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '角色名稱', value: 'title',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '角色ID', value: 'id',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '建立日期', value: 'created_at',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '修改日期', value: 'updated_at',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '狀態', value: 'status',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '功能', value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                }
            ],
            roles: [],
            roleName: '',
            addRoleDialog: false,
            isNewRoleValid: true,
            roleNameRules: [(v) => !!v || "必須填寫"],
            imageHeaders: [
                {
                    text: '縮圖',
                    sortable: false,
                    value: 'thumbnail',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '背景ID', value: 'image_id',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '建立日期', value: 'created_at',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '狀態', value: 'status',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '功能', value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                }
            ],
            imageHeadersWithComment: [
                {
                    text: '縮圖',
                    sortable: false,
                    value: 'thumbnail',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '背景ID', value: 'image_id',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '建立日期', value: 'created_at',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '狀態', value: 'status',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '審核意見', value: 'comment',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '功能', value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                }
            ],
            editBackgroundDialog: false,
            backgrounds: [],
            background: {
                editOptionFab: false,
                imgUrl: '',
            },
            editedBackground: {
                imgUrl: '',
                backgroundFiles: '',
                isNew: false,
                id: ''
            },
            backgroundOption: {
                img: '',
                fixedNumber: [6, 5],
                autoCropHeight: 500,
                autoCropWidth: 600,
            },
            applyBackgroundButtonDisabled: false,
            applyBackgroundButtonShow: false,
            backgroundFileRules: [(v) => (!!v || !!this.editedBackground.imgUrl) || "必須上傳圖片"],
            deleteImageDialog: false,
            deleteImageItem: {},
            dialogs: [],
            editBubbleDialog: false,
            bubble: {
                editOptionFab: false,
                imgUrl: '',
            },
            editedBubble: {
                imgUrl: '',
                bubbleFiles: '',
                isNew: false,
                id: ''
            },
            bubbleOption: {
                img: '',
                fixedNumber: [1, 1],
                autoCropHeight: 1200,
                autoCropWidth: 1200,
            },
            applyBubbleButtonDisabled: false,
            applyBubbleButtonShow: false,
            bubbleFileRules: [(v) => (!!v || !!this.editedBubble.imgUrl) || "必須上傳圖片"],
            typeBackground: '1',
            typeDialog: '2',
            bubbleTypeSquare: true,
            bubbleTypeDialog: false,
            selectedBubbleType: null,
            squareBubbleOption: {
                img: '',
                fixedNumber: [1, 1],
                autoCropHeight: 1200,
                autoCropWidth: 1200,
            },
            rectangleBubbleOption: {
                img: '',
                fixedNumber: [4, 3],
                autoCropHeight: 900,
                autoCropWidth: 1200,
            },
            backgroundHeaderWithComment: false,
            bubbleHeaderWithComment: false,
        };
    },
    async created() {
        this.paintStyleId = this.$route.params.pid;

        this.breadcrumbsItems.push({
            text: '主題管理',
            disabled: true,
            href: ``,
        });
        const infoList = await themeServices.getThemeInfo(this.paintStyleId,);
        let i = 0;
        infoList.forEach(info => {
            let localeName = this.disableLocale(info.locale)
            this.basicInfos.push({
                editOptionFab: false,
                id: i,
                themeId: info.id,
                locale: info.locale,
                localeName: localeName,
                name: info.name,
                bannerUrl: info.banner_url,
                logoUrl: info.logo_url,
                avatarUrl: info.avatar_url,
            });
            i++;
        });
        window.addEventListener("resize", this.windowResize);

        const themeRoles = await themeServices.getThemeRoles(this.paintStyleId);
        this.roles = themeRoles;
        // i = 0;
        // const responseData = await backgroundServices.getBackgroundList(this.paintStyleId);
        // responseData.backgrounds.forEach(bg => {
        //     this.backgrounds.push({
        //         thumbnail: bg.thumbnail,
        //         background_id: bg.id,
        //         status_code: bg.status_code,
        //         id: i,
        //         created_at: bg.create_date,
        //     });
        //     i++;
        // });
        this.fetchBackgrounds();
        this.fetchBubbles();

    },
    mounted() {
    },
    methods: {
        disableLocale(selectedLocale) {
            const locale = this.locales.find(locale => locale.id == selectedLocale);
            locale.disabled = true;
            return locale.name;
        },
        enableLocale(selectedLocale) {
            const locale = this.locales.find(locale => locale.id == selectedLocale);
            locale.disabled = false;
        },
        addLocale() {
            let selectedLocale = this.localeSelector;

            if (selectedLocale == '') {
                return;
            }
            const locale = this.locales.find(locale => locale.id == selectedLocale);

            this.localeSelector = '';
            this.editStyleInfo = {
                name: null,
                logoUrl: null,
                bannerUrl: null,
                logoFiles: null,
                bannerFiles: null,
                localeName: locale.name,
                locale: selectedLocale,
                newLocale: true
            }
            this.editThemeDialog = true;
        },
        async deleteLocale(item) {
            await themeServices.deleteInfo(item.themeId, item.locale);
            this.basicInfos.splice(item.id, 1);
        },

        openEditThemeDialog(item) {
            this.editStyleInfo = {
                name: item.name,
                logoUrl: item.logoUrl,
                bannerUrl: item.bannerUrl,
                avatarUrl: item.avatarUrl,
                logoFiles: null,
                bannerFiles: null,
                avatarFiles: null,
                localeName: item.localeName,
                locale: item.locale,
                id: item.id
            }
            this.editThemeDialog = true;
        },
        windowResize() {
            this.$cropperResize(this.$refs.bannerBlock, this.$refs.bannerCropper, this.bannerOption);
            this.$cropperResize(this.$refs.logoBlock, this.$refs.logoCropper, this.logoOption);
            this.$cropperResize(this.$refs.backgroundBlock, this.$refs.backgroundCropper, this.backgroundOption);
        },

        /*
        scaleImg() {
            if (this.scale == this.lastScale)
                return;
            let scaleValue = this.scale - this.lastScale
            this.lastScale = this.scale;
            if (this.scale == 0)
                this.$refs.cropper.refresh()
            else
                this.$refs.cropper.changeScale(scaleValue)
        },*/

        uploadImage(uploadFile, fileOptoin) {
            //var file = e.target.files[0]
            var reader = new FileReader();
            reader.readAsArrayBuffer(uploadFile)
            reader.onload = () => {
                let data = null;
                if (typeof uploadFile === 'object') {
                    // 把Array Buffer转化为blob 如果是base64不需要
                    data = window.URL.createObjectURL(new Blob([uploadFile]))
                } else {
                    data = uploadFile
                }
                fileOptoin.img = data;
                this.windowResize();
            }
        },

        saveInfo() {
            this.isEditPaintStyleValid = true;
            if (this.$refs.paintStyleForm.validate()) {
                this.buttonLoading = true;
                // let logoUrl = null;
                // Promise.all([this.$getCropperImg(this.$refs.logoCropper), this.$getCropperImg(this.$refs.bannerCropper)])
                //     .then(async result => {
                Promise.all([this.$getCropperData(this.$refs.logoCropper),
                this.$getCropperData(this.$refs.bannerCropper),
                this.$getCropperData(this.$refs.avatarCropper)])
                    .then(async result => {
                        let postData = {};
                        if (result[0].status == 1) {
                            postData['logo_img'] = result[0].data;
                        }
                        if (result[1].status == 1) {
                            postData['banner_img'] = result[1].data;
                        }
                        if (result[2].status == 1) {
                            postData['avatar_img'] = result[2].data;
                        }
                        postData['name'] = this.editStyleInfo.name;
                        postData['locale'] = this.editStyleInfo.locale;

                        const updateResult = await themeServices.saveInfo(this.paintStyleId, postData);

                        if (updateResult.logo_image_url) {
                            const randomNumber = ((Math.random() * 46656) | 0);
                            this.editStyleInfo.logoUrl = `${updateResult.logo_image_url}?${randomNumber}`;
                        }
                        if (updateResult.banner_image_url) {
                            const randomNumber = ((Math.random() * 46656) | 0);
                            this.editStyleInfo.bannerUrl = `${updateResult.banner_image_url}?${randomNumber}`;
                        }
                        if (updateResult.avatar_image_url) {
                            const randomNumber = ((Math.random() * 46656) | 0);
                            this.editStyleInfo.avatarUrl = `${updateResult.avatar_image_url}?${randomNumber}`;
                        }
                        if (this.editStyleInfo.newLocale) {
                            let localeName = this.disableLocale(this.editStyleInfo.locale)
                            this.basicInfos.push({
                                editOptionFab: false,
                                id: this.basicInfos.length,
                                locale: this.editStyleInfo.locale,
                                localeName: localeName,
                                name: this.editStyleInfo.name,
                                bannerUrl: this.editStyleInfo.bannerUrl,
                                logoUrl: this.editStyleInfo.logoUrl,
                                avatarUrl: this.editStyleInfo.avatarUrl,
                            })
                        } else {
                            this.basicInfos.forEach(item => {
                                if (item.id === this.editStyleInfo.id) {
                                    item.name = this.editStyleInfo.name;
                                    item.logoUrl = this.editStyleInfo.logoUrl;
                                    item.bannerUrl = this.editStyleInfo.bannerUrl;
                                    item.avatarUrl = this.editStyleInfo.avatarUrl;
                                }
                            });
                        }
                        this.buttonLoading = false;
                        this.editThemeDialog = false;
                    });
            }

        },
        newRole() {
            this.roleName = '';
            this.addRoleDialog = true;
        },
        async saveRole() {
            if (this.$refs.roleForm.validate()) {
                const data = {
                    role_name: this.roleName,
                    theme_id: this.paintStyleId
                }
                const response = await roleServices.addRole(data);
                let creatTime = new Date().toISOString()
                    .replace('T', ' ')
                    .replace('Z', '');
                creatTime = creatTime.slice(0, creatTime.length - 4);
                if (response.return_code == '0300001') {
                    this.roles.push({
                        id: response.role_id,
                        title: this.roleName,
                        created_at: creatTime,
                        status_code: 0
                    })
                }
                this.addRoleDialog = false;
            }
        },
        newBackground() {
            this.addBackgroundDialog = true;
        },
        getStatusText(statusCode) {
            switch (statusCode) {
                case 0:
                    return '未送審';
                case 2:
                    return '審核中';
                case 3:
                    return '未通過審核';
                case -1:
                    return '已下架';
                default:
                    return '已上架';
            }
        },
        goRoleDetail(id) {
            this.$router.push(`/console/theme/${this.paintStyleId}/role/${id}`);
        },
        openEditBackgroundDialog(item) {
            if (item) {
                this.editedBackground = {
                    id: item.id,
                    url: item.thumbnail,
                    files: null,
                    isNew: false
                }
                this.background.imgUrl = item.thumbnail;
            } else {
                this.editedBackground = {
                    id: this.backgrounds.length + 1,
                    url: '',
                    files: null,
                    isNew: true
                }
                this.background.imgUrl = '';
            }
            this.editBackgroundDialog = true;
        },
        async saveBackground() {
            if (this.$refs.backgroundForm.validate()) {
                this.buttonLoading = true;
                const result = await this.$getCropperData(this.$refs.backgroundCropper);
                if (result.status == 1) {
                    const postData = {
                        image: result.data,
                        theme_id: this.paintStyleId,
                        type: imageServices.TYPE_BACKGROUND
                    }
                    const updateResult = await imageServices.addImage(postData);
                    const randomNumber = ((Math.random() * 46656) | 0);
                    this.backgrounds.push({
                        image_id: updateResult.id,
                        thumbnail: `${updateResult.url}?${randomNumber}`,
                        status_code: 0,
                        created_at: updateResult.create_date,
                        id: this.editedBackground.id
                    })
                    this.applyBackgroundButtonDisabled = false;
                    this.applyBackgroundButtonShow = true;
                    this.editedBackground = {};
                    this.buttonLoading = false;
                    this.editBackgroundDialog = false;
                }

            }
        },
        async applyBackground() {
            this.buttonLoading = true;
            const data = {
                themeId: this.paintStyleId,
                type: this.typeBackground
            }
            await imageServices.apply(data);
            await this.fetchBackgrounds();
            this.buttonLoading = false;
        },
        openEditBubbleDialog() {
            if (this.selectedBubbleType == null) {
                this.selectedBubbleType = 'rectangle';
                this.bubbleTypeSquare = false;
                this.bubbleOption = this.rectangleBubbleOption;
            }
            this.editedBubble = {
                id: this.dialogs.length + 1,
                url: '',
                files: null,
                isNew: true
            }
            this.bubble.imgUrl = '';
            this.editBubbleDialog = true;
        },
        async saveBubble() {
            if (this.$refs.bubbleForm.validate()) {
                this.buttonLoading = true;
                const result = await this.$getCropperData(this.$refs.bubbleCropper);
                if (result.status == 1) {
                    let postData = {
                        image: result.data,
                        theme_id: this.paintStyleId,
                        type: this.typeDialog
                    }
                    if (this.bubbleTypeSquare) {
                        postData['center_width'] = 740;
                        postData['center_height'] = 740;
                        postData['center_x'] = 230;
                        postData['center_y'] = 230;
                        postData['title'] = '5x5';
                    } else {
                        postData['center_width'] = 860;
                        postData['center_height'] = 520;
                        postData['center_x'] = 170;
                        postData['center_y'] = 190;
                        postData['title'] = '5x8';
                    }
                    // const updateResult = await imageServices.addImage(postData);
                    await imageServices.addImage(postData);
                    // const randomNumber = ((Math.random() * 46656) | 0);
                    // this.dialogs.push({
                    //     image_id: updateResult.id,
                    //     thumbnail: `${updateResult.url}?${randomNumber}`,
                    //     status_code: 0,
                    //     created_at: updateResult.create_date,
                    //     id: this.editedBubble.id
                    // })
                    await this.fetchBubbles();
                    this.editedBubble = {};
                    this.buttonLoading = false;
                    this.editBubbleDialog = false;
                }

            }
        },
        async applyBubble() {
            this.buttonLoading = true;
            const data = {
                themeId: this.paintStyleId,
                type: this.typeDialog
            }
            await imageServices.apply(data);
            await this.fetchBubbles();
            this.buttonLoading = false;
        },
        openDeleteImageDialog(item, type) {
            this.deleteImageItem = item;
            this.deleteImageItem.type = type;
            this.deleteImageDialog = true;
        },
        async deleteImage() {
            this.buttonLoading = true;
            await imageServices.deleteImage(this.deleteImageItem.image_id);
            if (this.deleteImageItem.type == imageServices.TYPE_BACKGROUND) {
                for (let i = 0; i < this.backgrounds.length; i++) {
                    if (this.backgrounds[i].id == this.deleteImageItem.id) {
                        this.backgrounds.splice(i, 1);
                        break;
                    }
                }
                this.deleteImageItem = {};
                this.buttonLoading = false;
                this.deleteImageDialog = false;
            } else {
                /// Bubble
                for (let i = 0; i < this.dialogs.length; i++) {
                    if (this.dialogs[i].id == this.deleteImageItem.id) {
                        this.dialogs.splice(i, 1);
                        break;
                    }
                }
                this.deleteImageItem = {};
                this.buttonLoading = false;
                this.deleteImageDialog = false;

            }
        },
        async fetchBackgrounds() {
            const responseData = await imageServices.getImageList(
                this.paintStyleId, imageServices.TYPE_BACKGROUND);
            this.backgrounds = [];
            let i = 0;
            let unreviewed = false;
            let pending = false;
            this.backgroundHeaderWithComment = false;
            for (let bg of responseData.images) {
                if (bg.status_code == 2) {
                    pending = true;
                } else if (bg.status_code == 0) {
                    unreviewed = true;
                }
                if (bg.status_code == 3) {
                    const result = await imageServices.getImageReview(bg.id);
                    try {
                        const parsedValue = JSON.parse(result.review.comment);
                        let reviewMessage = '';
                        for (const item in parsedValue) {
                            if (parsedValue[item]['status'] == '0') {
                                if (reviewMessage.length > 0) {
                                    reviewMessage += '、';
                                }
                                reviewMessage += `${parsedValue[item]['message']}`;
                            }
                        }
                        this.backgrounds.push({
                            thumbnail: bg.thumbnail,
                            image_id: bg.id,
                            status_code: bg.status_code,
                            id: i,
                            created_at: bg.create_date,
                            comment: reviewMessage
                        });
                    } catch (error) {
                        this.backgrounds.push({
                            thumbnail: bg.thumbnail,
                            image_id: bg.id,
                            status_code: bg.status_code,
                            id: i,
                            created_at: bg.create_date,
                            comment: result.review.comment
                        });
                    }
                    this.backgroundHeaderWithComment = true;
                } else {
                    this.backgrounds.push({
                        thumbnail: bg.thumbnail,
                        image_id: bg.id,
                        status_code: bg.status_code,
                        id: i,
                        created_at: bg.create_date
                    });
                }
                i++;
            }
            this.applyBackgroundButtonShow = false;
            if (pending) {
                this.applyBackgroundButtonDisabled = true;
                this.applyBackgroundButtonShow = true;
            }
            if (unreviewed) {
                this.applyBackgroundButtonDisabled = false;
                this.applyBackgroundButtonShow = true;
            }
        },
        async fetchBubbles() {
            const responseData = await imageServices.getImageList(
                this.paintStyleId, imageServices.TYPE_DIALOG);
            this.dialogs = [];
            let i = 0;
            let unreviewed = false;
            let pending = false;
            this.bubbleHeaderWithComment = false;
            for (let image of responseData.images) {
                if (image.status_code == 2) {
                    pending = true;
                } else if (image.status_code == 0) {
                    unreviewed = true;
                }
                if (image.status_code == 3) {
                    const result = await imageServices.getImageReview(image.id);
                    try {
                        const parsedValue = JSON.parse(result.review.comment);
                        let reviewMessage = '';
                        for (const item in parsedValue) {
                            if (parsedValue[item]['status'] == '0') {
                                if (reviewMessage.length > 0) {
                                    reviewMessage += '、';
                                }
                                reviewMessage += `${parsedValue[item]['message']}`;
                            }
                        }
                        this.dialogs.push({
                            thumbnail: image.thumbnail,
                            image_id: image.id,
                            status_code: image.status_code,
                            id: i,
                            created_at: image.create_date,
                            comment: reviewMessage
                        });
                    } catch (error) {
                        this.dialogs.push({
                            thumbnail: image.thumbnail,
                            image_id: image.id,
                            status_code: image.status_code,
                            id: i,
                            created_at: image.create_date,
                            comment: result.review.comment
                        });
                    }
                    this.bubbleHeaderWithComment = true;
                } else {
                    this.dialogs.push({
                        thumbnail: image.thumbnail,
                        image_id: image.id,
                        status_code: image.status_code,
                        id: i,
                        created_at: image.create_date,
                    });
                }
                i++;
            }
            this.applyBubbleButtonShow = false;
            if (pending) {
                this.applyBubbleButtonDisabled = true;
                this.applyBubbleButtonShow = true;
            }
            if (unreviewed) {
                this.applyBubbleButtonDisabled = false;
                this.applyBubbleButtonShow = true;
            }
        },
        bubbleTypeSelectionChange() {
            if (this.selectedBubbleType == 'rectangle') {
                this.bubbleTypeSquare = false;
                this.bubbleOption = this.rectangleBubbleOption;
            } else {
                this.bubbleTypeSquare = true;
                this.bubbleOption = this.squareBubbleOption;
            }
            if (this.editedBubble.bubbleFiles) {
                this.uploadImage(this.editedBubble.bubbleFiles, this.bubbleOption);
            }
        }
    },
    computed: {
        backgroundHeaders() {
            if (this.backgroundHeaderWithComment) {
                return this.imageHeadersWithComment;
            } else {
                return this.imageHeaders;
            }
        },
        bubbleHeaders() {
            if (this.bubbleHeaderWithComment) {
                return this.imageHeadersWithComment;
            } else {
                return this.imageHeaders;
            }
        },
    },
};
</script>

<style scoped>
.role-thumbnail {
    width: 70px;
    align-items: center;
}

.background-block {
    width: 240px;
}

.bubble-block {
    width: 240px;
}

.bubble-rectangle-block {
    width: 240px;
}

.bubble-example-block {
    width: 300px;
}

.avatar-block {
    width: 60px;
}

.avatar-block>div {
    aspect-ratio: 1 / 1;
}

.background-block>div {
    aspect-ratio: 6 / 5;

}

.bubble-block>div {
    aspect-ratio: 1 / 1;
}

.bubble-rectangle-block>div {
    aspect-ratio: 4 / 3;
}


@media (min-width: 600px) {
    .background-block {
        width: 400px;
    }

    .bubble-block {
        width: 400px;
    }

    .bubble-rectangle-block {
        width: 400px;
    }

    .avatar-block {
        width: 100px;
    }
}

@media (min-width: 960px) {
    .background-block {
        width: 440px;
    }

    .bubble-block {
        width: 440px;
    }

    .bubble-rectangle-block {
        width: 440px;
    }

    .avatar-block {
        width: 110px;
    }
}

@media (min-width: 1264px) {
    .background-block {
        width: 440px !important;
    }

    .bubble-block {
        width: 440px !important;
    }

    .bubble-rectangle-block {
        width: 440px !important;
    }

    .avatar-block {
        width: 150px;
    }
}
</style>