export const state = {
    user: null,
    consoleIndexUrl: null,
    consoleFuncList: [],
    contestIndexUrl: null,
    contestFuncList: [],
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '',
    drawer: null,
    hasFinishFirstRound: false,
}