import Vue from 'vue'
import VueRouter from 'vue-router'
// import Index from '@/components/Index'

import consoleIndex from '@/components/console/Index'
import consoleLogin from '@/components/console/Login'
import loginRedirect from '@/components/login/Redirect'

import artistIndex from '@/components/console/artist/Index'

import themeList from '@/components/console/theme/List'
import themeIndex from '@/components/console/theme/Index'
import themeRoleInfo from '@/components/console/theme/RoleInfo'

import reviewRoleList from '@/components/console/review/Roles'
import reviewRoleDetail from '@/components/console/review/RoleDetail'

import reviewBackgroundList from '@/components/console/review/BackgroundList'
import reviewBackgroundDetail from '@/components/console/review/BackgroundDetail'
import reviewDialogList from '@/components/console/review/DialogList'
import reviewDialogDetail from '@/components/console/review/DialogDetail'

import drawer from '@/components/console/Drawer'

import NotFound from '@/components/NotFound'
import ErrorPage from '@/components/ErrorPage'
//import store from '@/store'

Vue.use(VueRouter)

const routes = [
    { path: '/logout', redirect: '/' },
    {
        path: '/',
        name: 'Index',
        component: consoleLogin,
        meta: {
            layout: 'default'
        }
    },

    {
        path: '/console/',
        name: 'consoleIndex',
        component: consoleIndex,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/login',
        name: 'consoleLogin',
        component: consoleLogin,
        meta: {
            layout: 'default'
        }
    },

    {
        path: '/login/redirect',
        name: 'loginRedirect',
        component: loginRedirect,
        meta: {
            layout: 'default'
        }
    },

    {
        path: '/console/my',
        name: 'artistIndex',
        component: artistIndex,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/console/theme',
        name: 'themeList',
        component: themeList,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/console/theme/:pid',
        name: 'themeIndex',
        component: themeIndex,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },
    {
        path: '/console/theme/:pid/role/:rid',
        name: 'themeRoleInfo',
        component: themeRoleInfo,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/console/review/roles',
        name: 'reviewRoleList',
        component: reviewRoleList,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/console/review/roles/:id',
        name: 'reviewRoleDetail',
        component: reviewRoleDetail,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/console/review/backgrounds',
        name: 'reviewBackgroundList',
        component: reviewBackgroundList,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/console/review/backgrounds/:id',
        name: 'reviewBackgroundDetail',
        component: reviewBackgroundDetail,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/console/review/dialogs',
        name: 'reviewDialogList',
        component: reviewDialogList,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/console/review/dialogs/:id',
        name: 'reviewDialogDetail',
        component: reviewDialogDetail,
        meta: {
            layout: 'console',
            requiresAuth: true,
            requiresAuthType: 'console'
        }
    },

    {
        path: '/console/drawer',
        name: 'drawer',
        component: drawer,
        meta: {
            layout: 'default'
        }
    },

    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '/error',
        component: ErrorPage,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: NotFound,
        meta: {
            layout: 'portal'
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})
/*
router.beforeEach((to, from, next) => {
    // 如果 router 轉跳的頁面需要驗證 requiresAuth: true
    //console.log('to=', to.fullPath, '| from=', from.fullPath);
    if (to.matched.some(record => {
        //console.log(record.name, record.meta.requiresAuth);
        return record.meta.requiresAuth;
    })) {
        const authType = to.meta.requiresAuthType;
        let paramUser = 'userName';
        let paramLogin = '/login';
        let paramFuncList = '/funcList';
        if (authType == 'console') {
            paramUser = 'adminName';
            paramLogin = '/console/login';
            paramFuncList = 'consoleFuncList';
        }
        // 如果沒有 token 
        let flag = localStorage.getItem(paramUser);
        //console.log('adminName?', sessionStorage.getItem('adminName'));
        if (!flag) {
            // 轉跳到 login page
            next({ path: paramLogin });
        } else {
            //要判斷是否能進去
            let isAllow = true;

            const funcListInSession = localStorage.getItem(paramFuncList);
            
            if (funcListInSession && to.fullPath != '/console' && to.fullPath != '/') {
                isAllow = false;
                const funcList = JSON.parse(funcListInSession);
                for (let funcUrl of funcList) {
                    if (to.fullPath.indexOf(funcUrl) != -1) {
                        isAllow = true;
                        break;
                    }
                }
            }
            if (isAllow) {
                next(); //成功可以進去
            } else {
                next({ path: '/404' })
            }
        }
    } else {
        next(); // 往下繼續執行
    }
});
*/
export default router;