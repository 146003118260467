import serviceUtils from "../utils/service";

export const imageServices = {
    TYPE_BACKGROUND: '1',
    TYPE_DIALOG: '2',
    async addImage(payload) {
        const formData = new FormData();
        for (let key in payload) {
            formData.append(key, payload[key]);
        }

        try {
            const url = `${serviceUtils.resourceUrl('imageAdd')}`;
            const response = await serviceUtils.postFormData(url, formData);
            return response.data;
        } catch (err) {
            console.log(`failed to addImage err: ${err}`);
            throw err
        }
    },
    async getImageList(themeId, type) {
        try {
            const url = `${serviceUtils.resourceUrl('imageList')}`
                .replace(':themeId', themeId)
                .replace(':type', type);
            const response = await serviceUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to getImageList err: ${err}`);
            throw err
        }
    },
    async deleteImage(id) {
        try {
            const url = `${serviceUtils.resourceUrl('imageRemove')}`
                .replace(':id', id);
            const response = await serviceUtils.delete(url);
            return response.data;
        } catch (err) {
            console.log(`failed to deleteImage err: ${err}`);
            throw err
        }
    },
    async apply(payload) {
        try {
            const url = `${serviceUtils.resourceUrl('imageApply')}`;
            const response = await serviceUtils.post(url, payload);
            return response.data;
        } catch (err) {
            console.log(`failed to apply err: ${err}`);
            throw err
        }
    },
    async getImageReview(id) {
        try {
            const url = `${serviceUtils.resourceUrl('imageReview')}`
                .replace(':id', id);
            const response = await serviceUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to getImageReview err: ${err}`);
            throw err
        }
    }
}