import servicesUtils from '../utils/service'

export const themeServices = {

    async getList(page) {
        try {
            const params = servicesUtils.queryParams({ page: page });
            const url = `${servicesUtils.resourceUrl('themeList')}${params}}`;
            const response = await servicesUtils.get(url);
            let data = [];
            for (let item of response.data.list) {
                data.push({
                    id: item.id,
                    title: item.name,
                    create_date: item.create_date,
                    update_date: item.update_date ? item.update_date : ''
                });
            }
            return {
                data: data,
                page: response.data.current_page,
                pageSize: response.data.page_size,
                total: response.data.total,
                totalPage: response.data.total_page
            };
        } catch (err) {
            console.log(`failed to getList err: ${err}`);
            throw err
        }
    },
    async getThemeInfo(id) {
        try {
            const url = `${servicesUtils.resourceUrl('themeInfo')}`
                .replace(':id', id);
            const response = await servicesUtils.get(url);
            let data = [];
            const responseId = response.data.id;
            for (let item of response.data.infos) {
                data.push({
                    id: responseId,
                    locale: item.locale,
                    name: item.name,
                    banner_url: item.banner_url,
                    logo_url: item.logo_url,
                    avatar_url: item.avatar_url
                });
            }
            return data;
        } catch (err) {
            console.log(`failed to getList err: ${err}`);
            throw err
        }
    },

    async addTheme(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('themeAdd')}`;
            const response = await servicesUtils.post(url, payload);
            return response.data;
        } catch (err) {
            console.log(`failed to getList err: ${err}`);
            throw err
        }
    },

    async getThemeRoles(id) {
        try {
            const url = `${servicesUtils.resourceUrl('themeRoles')}`
                .replace(':id', id);
            const response = await servicesUtils.get(url);
            let roles = [];
            for (const role of response.data.roles) {
                const result = await this.getRoleInfo(role.role);
                let statusCode = Number(role.state);
                if (role.state == 0 || role.state == -1) {
                    statusCode = await this.getRoleStatus(role.role).then((res) => {
                        return Number(res.return_code);
                    });
                }
                const names = result.names;
                let title = '';
                for (let name of names) {
                    if (name.locale === 'zh') {
                        title = name.name;
                    }
                }
                const roleInfo = {
                    id: result.id,
                    title: title,
                    thumbnail: result.thumbnail,
                    created_at: result.created_at,
                    updated_at: result.updated_at,
                    status_code: statusCode
                };
                roles.push(roleInfo);
            }
            return roles;
        } catch (err) {
            console.log(`failed to getList err: ${err}`);
            throw err
        }
    },

    async getRoleInfo(id) {
        try {
            const url = `${servicesUtils.resourceUrl('roleInfo')}`
                .replace(':id', id);
            const response = await servicesUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to getList err: ${err}`);
            throw err
        }
    },

    async getRoleStatus(id) {
        try {
            const url = `${servicesUtils.resourceUrl('roleStatus')}`
                .replace(':id', id);
            const response = await servicesUtils.get(url);
            return response.data;
        } catch (err) {
            console.log(`failed to updateRoleInfo err: ${err}`);
            throw err
        }
    },

    async checkThemeNameExist(payload) {
        try {
            if (typeof payload.name == 'undefined') {
                return null;
            }
            //const url = `${servicesUtils.resourceUrl('checkStyleName')}`+ '?' + servicesUtils.queryParams(payload)
            //const data = await servicesUtils.get(url)
            let data = { result: false }
            return data.result;
        } catch (err) {
            console.log(`failed to checkStyleName err: ${err}`)
            throw err
        }
    },

    async saveInfo(id, payload) {

        const formData = new FormData();

        for (let key in payload) {
            formData.append(key, payload[key]);
        }

        try {
            const url = `${servicesUtils.resourceUrl('themeInfoEdit')}`
                .replace(':id', id);
            const response = await servicesUtils.postFormData(url, formData);
            return response.data;
        } catch (err) {
            console.log(`failed to saveInfo err: ${err}`);
            throw err
        }
    },

    async deleteInfo(id, locale) {
        try {
            const url = `${servicesUtils.resourceUrl('themeInfoDelete')}`
                .replace(':id', id)
                .replace(':locale', locale);
            const response = await servicesUtils.delete(url);
            return response.data;
        } catch (err) {
            console.log(`failed to deleteInfo err: ${err}`);
            throw err
        }
    }
}