var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{staticClass:"pa-0 mb-2",attrs:{"items":_vm.breadcrumbsItems,"large":""}}),(_vm.itemSelected)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"success","small":"","loading":_vm.buttonLoading},on:{"click":_vm.approve}},[_vm._v(" 通過 ")]),_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.openReviewDialog()}}},[_vm._v(" 拒絕 ")])],1)],1)])],1):_vm._e(),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.backgrounds,"items-per-page":10,"loading":_vm.dataLoading},scopedSlots:_vm._u([{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","color":"grey darken-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"image-thumbnail mx-auto my-2",attrs:{"src":item.thumbnail}},'v-img',attrs,false),on))]}}],null,true)},[_c('v-img',{staticClass:"grey darken-3",staticStyle:{"width":"600px"},attrs:{"src":item.thumbnail}})],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'red--text': item.status === -1, 'green--text': item.status === 1 }},[_vm._v(" "+_vm._s(_vm.getStatusText(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2 mt-1",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.gotoReviewBackgroundDetail(item.application_id)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-eye ")]),_vm._v(" 查看 ")],1)]}},{key:"item.checkbox",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"checkbox-container"},[(item.status === 0)?_c('v-checkbox',{model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}}):_vm._e()],1)]}}])})],1),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.reviewDialog),callback:function ($$v) {_vm.reviewDialog=$$v},expression:"reviewDialog"}},[_c('v-card',{attrs:{"dense":""}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("拒絕背景圖片申請")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"reviewForm"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"grey lighten-4",attrs:{"cols":"4","sm":"3","md":"3"}},[_c('v-subheader',[_vm._v("拒絕原因")])],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"8","sm":"9","md":"9"}},[_c('v-text-field',{attrs:{"label":"","rules":_vm.reviewMessageRules,"dense":"","hide-details":"auto"},model:{value:(_vm.reviewMessage),callback:function ($$v) {_vm.reviewMessage=$$v},expression:"reviewMessage"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.buttonLoading},on:{"click":function($event){_vm.reviewDialog = false}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"color":"light-green darken-2 white--text","loading":_vm.buttonLoading,"disabled":_vm.buttonLoading},on:{"click":_vm.saveReview}},[_vm._v(" 儲存 ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.previewDialog),callback:function ($$v) {_vm.previewDialog=$$v},expression:"previewDialog"}},[_c('v-card',{attrs:{"dense":""}},[_c('v-card-text',[_c('v-img',{staticClass:"preview-image mx-auto",attrs:{"src":_vm.previewImage}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }