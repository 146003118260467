<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>
        <v-row no-gutters  v-if="detail.status == 0">
            <v-col>
                <div>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            color="success" small
                            @click="approve">
                            通過
                        </v-btn>
                        <v-btn color="error" small
                            @click="openReviewDialog()">
                            拒絕
                        </v-btn>
                    </v-toolbar>
                </div>
            </v-col>
        </v-row>
        <v-card outlined>
            <v-row no-gutters>
                <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                    <v-subheader>圖片</v-subheader>
                </v-col>
                <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                    <v-img class="image-block" :src="detail.image_url"></v-img>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                    <v-subheader>文字區示意圖<br>紅色區塊為文字區域，應留空沒有圖樣</v-subheader>
                </v-col>
                <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                    <div class="text-bubble">
                        <img class="image image-block" :src="detail.image_url"/>
                        <div class="text-area" :style="textAreaStyles"></div>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                    <v-subheader>原始寬度</v-subheader>
                </v-col>
                <v-col cols="8" sm="9" md="9" lg="10" class="pa-2 my-auto">
                    {{ detail.width }}
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                    <v-subheader>原始高度</v-subheader>
                </v-col>
                <v-col cols="8" sm="9" md="9" lg="10" class="pa-2 my-auto">
                    {{ detail.height }}
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                    <v-subheader>文字區起始x座標</v-subheader>
                </v-col>
                <v-col cols="8" sm="9" md="9" lg="10" class="pa-2 my-auto">
                    {{ detail.center_x }}
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                    <v-subheader>文字區起始y座標</v-subheader>
                </v-col>
                <v-col cols="8" sm="9" md="9" lg="10" class="pa-2 my-auto">
                    {{ detail.center_y }}
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                    <v-subheader>文字區寬度</v-subheader>
                </v-col>
                <v-col cols="8" sm="9" md="9" lg="10" class="pa-2 my-auto">
                    {{ detail.center_width }}
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                    <v-subheader>文字區高度</v-subheader>
                </v-col>
                <v-col cols="8" sm="9" md="9" lg="10" class="pa-2 my-auto">
                    {{ detail.center_height }}
                </v-col>
            </v-row>
        </v-card>
        <v-dialog v-model="reviewDialog" max-width="600px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">拒絕對話框圖片申請</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="reviewForm">
                            <v-row no-gutters>
                                <v-col cols="4" sm="3" md="3" class="grey lighten-4">
                                    <v-subheader>拒絕原因</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="9" md="9" class="pa-2">
                                    <v-text-field label="" v-model="reviewMessage"
                                        :rules="reviewMessageRules" dense hide-details="auto"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="reviewDialog = false" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveReview" :loading="buttonLoading"
                        :disabled="buttonLoading">
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { reviewServices } from '@/services/review.js';
export default {
    name: 'BackgroundDetail',
    data() {
        return {
            breadcrumbsItems: [],
            buttonLoading: false,
            applicationId: '',
            imageId: '',
            detail: {},
            textAreaSetting: {
                width: 0,
                height: 0,
                positionX: 0,
                positionY: 0,
            },
            ratio: 1,
            reviewDialog: false,
            reviewMessage: '',
            reviewMessageRules: [(v) => !!v || '請輸入審核意見'],
        }
    },
    async created() {
        this.breadcrumbsItems.push({
            text: '對話框圖片詳情',
            disabled: true,
            href: ``,
        });
        this.applicationId = this.$route.params.id;
        const responseData = await reviewServices.getReviewImageDetail(this.applicationId);
        this.detail = responseData.details;
        this.ratio =  400 / this.detail.width;
        // this.ratio = this.detail.width / 400;
    },
    methods: {
        async approve() {
            this.buttonLoading = true;
            const data = {
                status: 1
            }
            await reviewServices.sendReviewImageResult(this.applicationId, data)
            this.buttonLoading = false;
            this.$router.go(-1);
        },
        openReviewDialog() {
            this.reviewDialog = true;
        },
        async saveReview() {
            if (this.$refs.reviewForm.validate()) {
                this.buttonLoading = true;
                const data = {
                    status: -1,
                    comment: this.reviewMessage
                }
                await reviewServices.sendReviewImageResult(this.applicationId, data)
                this.buttonLoading = false;
                this.$router.go(-1);
            }
        }
    },
    computed: {
        textAreaStyles() {
            return {
                width: `${this.detail.center_width * this.ratio}px`,
                height: `${this.detail.center_height * this.ratio}px`,
                top: `${this.detail.center_y * this.ratio}px`,
                left: `${this.detail.center_x * this.ratio}px`,
            };
        },
    },
}
</script>

<style scoped>
.image-block {
    width: 400px;
}

.image {
  display: block;
}

.text-bubble {
    position: relative;
}

.text-bubble > .text-area {
    position: absolute;
    background-color: rgba(255, 0, 0, 0.5);
}

/* @media (min-width: 600px) {
    .image-block {
        width: 400px;
    }
}

@media (min-width: 960px) {
    .image-block {
        width: 440px;
    }
}

@media (min-width: 1264px) {
    .image-block {
        width: 440px !important;
    }
} */
</style>