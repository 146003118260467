export const mutations = {
  setBearerToken(state, payload) {
    state.bearerToken = payload
  },
  setUser(state, payload) {
    state.user = payload
  },
  SET_BAR_IMAGE(state, payload) {
    state.barImage = payload
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload
  },
  setConsoleIndexUrl(state, payload) {
    state.consoleIndexUrl = payload
  },
  setConsoleFuncList(state, payload) {
    state.consoleFuncList = payload
  },
  setContestIndexUrl(state, payload) {
    state.contestIndexUrl = payload
  },
  setContestFuncList(state, payload) {
    state.contestFuncList = payload
  },
  setHasFinishFirstRound(state, payload) {
    state.hasFinishFirstRound = payload
  },
  setQuizTitle(state, payload) {
    console.log('setQuizTitle:', payload)
    state.quizTitle = payload
  },
}