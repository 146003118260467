import fetch from 'node-fetch'
import router from '@/router'

const httpHeaders = {
    'Content-Type': 'application/json; charset=UTF-8',
}

const checkStatus = (res) => {
    if (res.status >= 200 && res.status < 300) {
        return res
    } else {
        let error = new Error(res.statusText)
        let path = '/error';
        switch (res.status) {
            case 404: path = '/404'; break;
            case 401:
                // if (res.url.indexOf('console') != -1) {
                //     path = '/console/login';
                // } else {
                //     path = '/login';
                // }
                path = '/login';
                localStorage.clear();
                break;
        }
        router.push(path);
        throw error;
    }
}

export default {
    async get(url) {
        try {
            const res = await fetch(url, {
                method: 'get',
                headers: httpHeaders,
                credentials: 'include'
            })
            const data = checkStatus(res)
            return data.json()
        } catch (err) {
            console.log(`client failed to get ${url}, err: ${err}`)
            throw err
        }
    },

    async post(url, d) {
        try {
            return fetch(url, {
                method: 'post',
                keepalive: true,
                body: JSON.stringify(d),
                headers: httpHeaders,
                credentials: 'include'
            }).then(res => {
                const data = checkStatus(res)
                return data.json()
            })

        } catch (err) {
            console.log(`client failed to post ${url}, err: ${err}`)
            throw err
        }
    },

    async postFormData(url, d) {
        try {
            const headers = Object.assign({}, httpHeaders)
            delete headers['Content-Type'];
            return fetch(url, {
                method: 'post',
                body: d,
                headers: headers,
                credentials: 'include'
            }).then(res => {
                const data = checkStatus(res)
                return data.json()
            })

        } catch (err) {
            console.log(`client failed to postFormData ${url}, err: ${err}`)
            throw err
        }
    },
    async delete(url) {
        try {
            const res = await fetch(url, {
                method: 'delete',
                headers: httpHeaders,
                credentials: 'include'
            })
            const data = checkStatus(res)
            return data.json()
        } catch (err) {
            console.log(`client failed to delete ${url}, err: ${err}`)
            throw err
        }
    },
    queryParams(params) {
        return Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
    },

    resourceUrl(r) {
        const resource = {
            // consoleLogin: '/console/signIn',
            // consoleLogout: '/console/logout',
            // consoleAdminName: '/console/my',
            // consoleChangePwd: '/console/changePwd',
            // consoleSysFunction: '/console/sysFunction',
            // consoleAdminUser: '/console/adminUser',
            // consoleAdminUserRoleList: '/console/adminUser/roles',
            // consoleContestStat: '/console/contest/stat',
            // consoleUserList: '/console/users',
            // consoleUserCheckSchool: '/console/users/:id',
            // consoleContestRoundPostList: '/console/contest/:round/post',
            // consoleContesPostScoring: '/console/contest/post/:id/score',
            // consoleContesPost: '/console/contest/post/:id',

            loginLink: '/login',

            artistInfo: '/my',
            artistUpdate: '/my/info',
            artistDeleteInfo: '/my/info/:locale',
            artistUpdateSocialMedia: '/my/socialmedia',
            artistDeleteSocialMedia: '/my/socialmedia/:type',
            artistUpdateThumbnail: '/my/thumbnail',
            artistUpdateCover: '/my/cover',

            themeAdd: '/theme/',
            themeList: '/theme/zh?',
            checkThemeName: '/theme/checkName',
            themeInfo: '/theme/info/:id',
            themeInfoEdit: '/theme/:id',
            themeInfoDelete: '/theme/:id/:locale',
            themeRoles: '/theme/:id/roles',

            // roleBrief: '/mr/:id',
            addRole: '/mr/addrole',
            roleInfo: '/mr/:id',
            roleThumbnail: '/mr/:id/thumbnail',
            roleFace: '/mr/:id/face',
            roleInfoLocale: '/mr/update/:id/:locale',
            roleInfoUpdate: '/mr/update/:id',
            roleEmotion: '/mr/:id/emotion/:emotionId',
            roleEmotionFile: '/mr/addFile',
            roleStatus: '/mr/:id/status',
            roleApply: '/mr/:id/apply',
            roleRevoke: '/mr/:id/revoke',
            roleApplyRevoke: '/mr/apply/:id',
            rolePreviewImage: '/mr/previewImage',
            // testDiary: '/ajax/diary',

            userInfo: '/user/info',
            userMenu: '/auth/sysFunction',
            userLogout: '/user/logout',

            consoleSysFunction: '/auth/sysFunction',

            imageAdd: '/image/add',
            imageList: '/image/list/:themeId/:type',
            imageRemove: '/image/:id',
            imageApply: '/image/apply',
            imageReview: '/image/review/:id',

            reviewRoleList: '/review/roles',
            reviewRoleApplication: '/mr/apply/:id/review',

            shopAddRole: '/shop/addrole',
            shopUpdateRole: '/shop/updaterole',
            shopGetRole: '/shop/getrole/:id',

            reviewImageList: '/review/images/:type',
            reviewImageDetail: '/review/images/detail/:id',
            reviewImage: '/review/images/:id',
            reviewGetArtist: '/review/artist/:id'
        }
        let url = (process.env.VUE_APP_API_URL) ? `${process.env.VUE_APP_API_URL}${resource[r]}` : resource[r]
        return url
    },
    diaryAPIUrl(r) {
        const resource = {
            compose: '/img/composite/',
        };
        let url = (process.env.VUE_APP_DIARY_API_URL) ? `${process.env.VUE_APP_DIARY_API_URL}${resource[r]}` : resource[r];
        return url;
    },
}