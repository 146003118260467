<template>
    <v-container>
        <v-card outlined>
            <v-card flat class="border-bottom" v-for="input in basicInfos" :key="input.id">
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>語言</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        {{ input.localeName }}

                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>名稱</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        {{ input.name }}
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>Logo</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        <v-img class="logo-block" :src="input.logoUrl" v-if="input.logoUrl"></v-img>
                        <span v-if="!input.logoUrl">無設定</span>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>主題大圖</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        <v-img class="banner-block" :src="input.bannerUrl" v-if="input.bannerUrl"></v-img>
                        <span v-if="!input.bannerUrl">無設定</span>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4" sm="3" md="3" lg="2" class="grey lighten-4">
                        <v-subheader>主題縮圖</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                        <v-img class="avatar-block" :src="input.avatarUrl" v-if="input.avatarUrl"></v-img>
                        <span v-if="!input.avatarUrl">無設定</span>
                    </v-col>
                </v-row>
            </v-card>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'ThemeIntro',
    props: ['themeInfo'],
    data() {
        return {
            locales: [{
                id: 'zh',
                name: '中文',
                disabled: false
            }, {
                id: 'en',
                name: '英文',
                disabled: false
            }],
            basicInfos: [],
        }
    },
    created() {
        const infoList = this.themeInfo;
        let i = 0;
        infoList.forEach(info => {
            let localeName = this.locales.find(locale => 
            locale.id == info.locale).name;
            this.basicInfos.push({
                editOptionFab: false,
                id: i,
                themeId: info.id,
                locale: info.locale,
                localeName: localeName,
                name: info.name,
                bannerUrl: info.banner_url,
                logoUrl: info.logo_url,
                avatarUrl: info.avatar_url,
            });
            i++;
        });
    }
}
</script>

<style scoped>
.avatar-block {
    width: 60px;
}

.avatar-block>div {
    aspect-ratio: 1 / 1;
}

@media (min-width: 600px) {
    .avatar-block {
        width: 100px;
    }
}

@media (min-width: 960px) {
    .avatar-block {
        width: 110px;
    }
}

@media (min-width: 1264px) {
    .avatar-block {
        width: 150px;
    }
}

</style>