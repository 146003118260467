export const getters = {
    getBearerToken(state) {
        return state.bearerToken
    },
    getUser(state) {
        return state.user
    },
    getConsoleIndexUrl(state) {
        return state.consoleIndexUrl
    },
    getConsoleFuncList(state) {
        return state.consoleFuncList
    },
    getContestIndexUrl(state) {
        return state.contestIndexUrl
    },
    getContestFuncList(state) {
        return state.contestFuncList
    },
    getHasFinishFirstRound(state) {
        return state.hasFinishFirstRound
    },
    getQuizTitle(state) {
        console.log('store:', state.quizTitle)
        return state.quizTitle
    },
}