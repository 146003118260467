<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>
        <v-card outlined>
            <v-data-table
                :headers="headers"
                :items="roles"
                :items-per-page="15"
                :loading="loadingRoles">
                <template v-slot:item.avatar="{ item }">
                    <v-img :src="item.avatar" alt="avatar image"
                        class="role-thumbnail mx-auto my-2">
                    </v-img>
                </template>
                <template #item.status="{ item }">
                    <span :class="{ 'red--text': item.status === -1, 'green--text': item.status === 1 }">
                        {{ getStatusText(item.status) }}
                    </span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn small class="mr-2 mt-1" outlined @click="gotoReviewRoleDetail(item.application_id)">
                        <v-icon small class="mr-1"> mdi-eye </v-icon>
                        查看
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import { reviewServices } from "@/services/review.js";
export default {
    name: "reviewRoleList",
    components: {
    },
    data() {
        return {
            breadcrumbsItems: [],
            addDialog: false,
            buttonLoading: false,
            infoMode: 'show',
            themeName: '',
            headers: [
                {
                    text: '縮圖',
                    sortable: false,
                    value: 'avatar',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '角色ID',
                    value: 'role_id',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '角色名稱',
                    value: 'role_name',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '申請日期',
                    value: 'apply_date',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '狀態',
                    value: 'status',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: '功能', value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
            ],
            roles: [],
            totalThemes: 0,
            themePage: 1,
            themePageCount: 1,
            loadingRoles: false,
        };
    },
    async created() {
        this.breadcrumbsItems.push({
            text: '角色審核',
            disabled: true,
            href: ``,
        });
        this.loadingRoles = true;
        const response = await reviewServices.getReviewRoleList();
        let i = 0;
        for (let role of response.roles) {
            this.roles.push({
                id: i,
                role_id: role.mangaRoleId,
                role_name: role.mangaRoleName,
                application_id: role.id,
                apply_date: role.applyDate,
                status: role.status,
                avatar: role.avatarUrl
            });
            i++;
        }
        this.loadingRoles = false;
    },
    mounted() {
    },
    watch: {},
    methods: {
        gotoReviewRoleDetail(id) {
            this.$router.push(`/console/review/roles/${id}`);
        },
        getStatusText(statusCode) {
            switch (statusCode) {
                case 0:
                    return '待審核';
                case 1:
                    return '已上架';
                case -2:
                    return '已取消';
                default:
                    return '已拒絕';
            }
        },
    },
};
</script>

<style scoped>
.role-thumbnail {
    width: 70px;
}
</style>