<template>
    <v-app-bar
        id="app-bar"
        absolute
        app
        color="orange accent-2"
        flat
        height="57"
        class="text-white">
        <v-btn
            class="mr-3 d-flex d-md-none white--text"
            text
            small
            @click="setDrawer(!drawer)">
            <v-icon> mdi-menu </v-icon>
        </v-btn>

        <v-toolbar-title class="d-flex d-md-none white--text"><v-btn
                text
                plain
                class="white--text text-h6 pa-0 ma-0"
                :to="getConsoleIndexUrl">角色管理平台</v-btn>
        </v-toolbar-title>
        <v-spacer />
        <v-menu
            bottom
            left
            offset-y
            origin="top right"
            transition="scale-transition">
            <template v-slot:activator="{ attrs, on }">
                <v-btn
                    class="ml-2 white--text"
                    min-width="0"
                    text
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>mdi-account</v-icon> {{ adminName }}
                </v-btn>
            </template>

            <v-list :tile="false" nav dense class="text-center">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn block text to="/console/my">我的帳號</v-btn>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn block text @click="userLogout()">登出</v-btn>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
// Components
// Utilities
import { mapState, mapMutations, mapGetters } from "vuex";
import { loginServices } from "@/services/login.js";

export default {
    name: "AppBar",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        adminName: localStorage.getItem("adminName"),
    }),
    computed: {
        ...mapState(["drawer"]),
        ...mapGetters(["getConsoleIndexUrl"]),
    },
    methods: {
        ...mapMutations({
            setDrawer: "SET_DRAWER",
        }),
        async userLogout() {
            await loginServices.logout();
            // localStorage.removeItem("adminName");
            localStorage.removeItem("consoleFuncList");
            //OAuth logout
            location.href = process.env.VUE_APP_LOGOUT_URL;
            // this.$router.push("/login");
        },
    },
};
</script>

<style scoped></style>