<template>
    <div id="app">
        <component :is="layout">
            <router-view v-if="isRouterAlive"/>
        </component>
    </div>
</template>

<script>

export default {
    name: "App",
    provide(){
        return {
            reload: this.reload
        }
    },
    data(){
        return {
            defaultLayout: 'Default',
            isRouterAlive: true,
        }
    },
    computed:{
        layout(){
            return (this.$route.meta.layout || this.defaultLayout)+'Layout'
        }
    },
    methods: {
        reload(){
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            })
        }
    }
};
</script>

<style>

#app {
    max-width: 100%;
    /* margin: 0 auto;
    padding: 2rem; */

    font-weight: normal;
    background-color: var(--v-background-lighten5);/*--v-background-base*/
}
.page-title {
    border-bottom: 2px solid #20780c;
}

.main-content {
    min-height: calc(100vh - 297px);
}
.main-content.error-page {
    min-height: 58vh; 
    margin-left: auto!important;
    margin-right: auto!important;
}
.w-100 {
    width: 100%;
}
/* @media (min-width: 1024px) {
    body {
        display: flex;
        place-items: center;
    }

    #app {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0 2rem;
    }

    .wrapper {
        display: flex;
        place-items: flex-start;
        flex-wrap: wrap;
    }

    nav {
        text-align: left;
        margin-left: -1rem;
        font-size: 1rem;

        padding: 1rem 0;
        margin-top: 1rem;
    }
} */
</style>